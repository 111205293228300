import {
    makeObservable,
    makeAutoObservable,
    observable,
    computed,
    action,
    flow,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {
    comp1 = { selectedRxno: null };

    constructor() {
        this.dbstore = dbstore;

        makeAutoObservable(this, {
            setComp1: action,

        });
    }

    setComp1(obj) {
        Object.assign(this.comp1, obj)
        // console.log(this.comp1)
    }

}

const rxListActionStore = new Store();
export { rxListActionStore };