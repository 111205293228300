import React from 'react';
import { Col, Row, Layout } from 'antd';
import ListIcon from '../assets/images/Calendar-Day-M.png';
import Styles from './Styles/HomeStyle.module.css';

const liststyle = {
    background: "#f0f0f7",
    padding: "1em",
    borderRadius: "1em",
    marginBottom: "1em",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer"
}
const box = {
    paddingTop: "auto",
    paddingBottom: "auto"
}

function DrListItem(props) {
    return (
        <>
            <Row onClick={() => {
                if (props.onClick) {
                    props.onClick()
                }
            }} style={liststyle}>
                <div>
                    <div>
                        <img src={ListIcon}></img>
                    </div>
                </div>
                <div className={Styles.perinfo} span={10}>
                    <div>{props?.item?.name}</div>
                    <div>{props?.item?.age} | {props?.item?.gender}</div>
                </div>
            </Row>
        </>
    );
}

export default DrListItem;