import "./ChatView.css";
import ChatContainer from "./ChatContainer";
import ChatList from "./ChatList";
import ChatBox from "./ChatBox";
import { chatStore } from "../dataLayer/stores/pages/chatStore";
import { observer } from "mobx-react";

const ChatView = observer(() => {
  return (
    <ChatContainer>
      {/* <!-- Users box--> */}
      <ChatList data={chatStore.comp1Data} />
      {/* <!-- Chat Box--> */}
      <ChatBox data={chatStore.comp2Data} />
    </ChatContainer>
  );
});

export default ChatView;
