import { inject, observer } from "mobx-react";
import { Card } from "react-bootstrap";
import { FaHeartbeat } from "react-icons/fa";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { Row, Col } from "antd";
const VitalsButton = observer(({ store, onClick }) => {
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Card.Body
        // className={"row m-0 pl-4 pr-4"}
        style={{
          fontFamily: "Nunito-SemiBold",
          fontSize: "32px",
          color: "#272D5C",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Row>
          <Col span={5} offset={1}>
            <FaHeartbeat
              size={50}
              className=" justify-content-center"
              color="#f50"
            />
          </Col>
          <Col span={14}>
            <span className="col text-center justify-content-center">
              Know Your Vitals
            </span>
          </Col>
          <Col span={4}>
            <IoChevronForwardCircleOutline size={40} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});

export default VitalsButton;
