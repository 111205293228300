import { Col, Row, Layout, message } from "antd";
import { useEffect, useState } from "react";
import VitalHeader from "../VitalHeader";
import VitalChart from "../VitalChart";
import VitalInput from "../VitalInput";
import { Card } from "react-bootstrap";
import VitalRecords from "../VitalRecords";
import ScrollToTopOnMount from "../nav/scrollToTopOnMount";
import { vitalsStore } from "../../dataLayer/stores/pages/vitalsStore";
import { observer } from "mobx-react";
import { dbstore } from "../../dataLayer/stores/dbStore";
var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
};
const Vitals = observer(({ props }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <ScrollToTopOnMount />
        <Col span={24}>
          <VitalHeader data={vitalsStore.comp1Data} />
        </Col>
        <Col span={14}>
          <Card
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: 30,
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
              padding: 30,
            }}
          >
            <VitalChart data={vitalsStore.comp2Data} />
          </Card>

          <Card
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              borderRadius: 30,
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
              padding: 30,
            }}
          >
            <VitalInput data={vitalsStore.comp3Data} onSubmit={async (obj) => {
              console.log('im called')
              var res = await dbstore.newVital(obj);
              if (res == 200) {
                message.success("Vital data updated successfully")
              } else {
                message.error("Oops! some thing went wrong, please try again")
              }
            }} />
          </Card>
        </Col>
        <Col span={10}>
          <VitalRecords data={vitalsStore.comp4Data} />
        </Col>
      </Row>
    </>
  );
});
export default Vitals;
