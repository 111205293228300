import { useState } from "react";
import { Button, Card, Col, Form } from "react-bootstrap";
import { Colours } from "../Colours";

const MonthlyReportCard = () => {
  // eslint-disable-next-line no-unused-vars
  const [items, setItems] = useState([
    {
      label: "January",
      value: "January",
      selected: true,
    },
    {
      label: "Febuary",
      value: "Febuary",
    },
    {
      label: "March",
      value: "March",
    },
    {
      label: "April",
      value: "April",
    },
    {
      label: "May",
      value: "May",
    },
    {
      label: "June",
      value: "June",
    },
    {
      label: "July",
      value: "July",
    },
    {
      label: "August",
      value: "August",
    },
    {
      label: "September",
      value: "September",
    },
    {
      label: "October",
      value: "October",
    },
    {
      label: "November",
      value: "November",
    },
    {
      label: "December",
      value: "December",
    },
  ]);
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        padding: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body>
        <Card.Title
          className={"text-center"}
          style={{
            fontFamily: "Nunito-Light",
            fontSize: 35,
            color: Colours.primaryText,
          }}
        >
          Your Monthly Report
        </Card.Title>
        <div className={"row mx-0 my-4 align-items-center"}>
          <Col
            xs={12}
            md={4}
            lg={3}
            style={{
              textAlign: "right",
              paddingRight: 0,
              justifyContent: "center",
            }}
          >
            Select
          </Col>
          <div
            className={"col-12 col-md-8 col-lg-9"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Form.Control
              as="select"
              size="md"
              className={"col-xs-12 col-md-9"}
              style={{
                backgroundColor: "#F5F5F5",
                borderRadius: 20,
                justifyContent: "center",
              }}
            >
              {items.map((item, ind) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Form.Control>
          </div>
        </div>
        <div
          className={"col-12"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            style={{
              backgroundColor: Colours.green,
              border: "none",
              borderRadius: 20,
              alignSelf: "center",
            }}
          >
            View PDF
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MonthlyReportCard;
