import { Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../../assets/images/logo@2x.png";
import logoName from "../../assets/images/logoname@2x.png";
import DrNavLink from "./NavLink";
import { HiOutlineHome, HiSupport } from "react-icons/hi";
import { RiBarChart2Fill, RiMailLine, RiBarcodeLine } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";

const SideNav = (props) => {
  return (
    <div
      className="p-0 bg-white full-height pt-3"
      style={{ overflow: "hidden" }}
    >
      <Link to="/" className="navbar-brand  mr-0 mb-2">
        <Container>
          <Row className="ml-0 mr-0">
            <Col xs={12}>
              <Image src={logo} fluid />
            </Col>
            <Col xs={12}>
              <Image src={logoName} fluid />
            </Col>
          </Row>
        </Container>
      </Link>
      <DrNavLink to="/home" title={"Home"} Icon={HiOutlineHome}></DrNavLink>
      <DrNavLink
        to="/reports"
        title={"Compliance Reports"}
        Icon={RiBarChart2Fill}
      ></DrNavLink>
      <DrNavLink
        to="/messages"
        title={"Messages"}
        Icon={RiMailLine}
      ></DrNavLink>
      <DrNavLink
        to="/prescriptions"
        title={"Prescriptions"}
        Icon={RiBarcodeLine}
      ></DrNavLink>
      <DrNavLink
        to="/profile"
        title={"Profile"}
        Icon={IoPersonOutline}
      ></DrNavLink>
      <DrNavLink
        to="/calendar"
        title={"Rx-Calendar"}
        Icon={FaRegCalendarAlt}
      ></DrNavLink>
      {/* <DrNavLink to="/help" title={"Help Center"} Icon={HiSupport}></DrNavLink>
      <DrNavLink
        to="/settings"
        title={"Settings"}
        Icon={IoMdSettings}
      ></DrNavLink> */}
    </div>
  );
};

export default SideNav;
