import { inject, observer } from "mobx-react";
import { Image, Row } from "react-bootstrap";
import tabIcon from "../assets/images/icon-awesome-tablet.png";
import { Colours } from "../Colours";
import { parsePillTime } from "../Common.fns";

const PillAdherenceStatusCard = observer(
  ({ compliance, className, onClick, store }) => {
    // const compliance = {
    //   date: "1622160000000",
    //   totalTablets: 3,
    //   status: null,
    //   time: "8:00",
    // };

    var complianceInfo = compliance;
    let { date, time } = complianceInfo;
    date = parseInt(date);
    console.log({ isLater: parseInt(time) > Date.now() });
    console.log(JSON.parse(JSON.stringify(compliance)));
    return (
      <div
        className={`${className} ml-2`}
        style={{
          ...{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "20px",
            width: 80,
            height: 80,
            boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          },
          ...(compliance.status === null
            ? {
                backgroundColor: Colours.primaryText,
              }
            : {
                backgroundImage: compliance.status
                  ? "linear-gradient(to bottom right, #28F0AF, #147858)"
                  : "linear-gradient(to bottom right, #FF4B1F, #FA5C56)",
              }),
        }}
        onClick={() => {
          onClick(time);
        }}
        type="button"
      >
        <div
          className={"col m-0 p-0"}
          style={{
            height: "100%",
            width: "100%",
            fontFamily: "Nunito-Light",
            fontSize: "12px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <span className="text-center">{parsePillTime(time)}</span>
          <Row className="align-self-stretch m-0 justify-content-around px-2">
            {new Array(compliance.totalTablets).fill(0).map((medicine, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    "col-4 m-0 p-0 px-0 mb-1 d-flex align-items-center justify-content-center"
                  }
                  // style={{ margin: 5 }}
                >
                  <Image src={tabIcon} fluid />
                </div>
              );
            })}
            {/* <div
              // key={idx}
              className={
                "col-4 m-0 p-0 px-0 mb-1 d-flex align-items-center justify-content-center"
              }
            >
              <Image src={tabIcon} fluid />
            </div> */}
          </Row>
        </div>
      </div>
    );
  }
);

export default PillAdherenceStatusCard;
