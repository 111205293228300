import { Card } from "react-bootstrap";
import { FaTablets } from "react-icons/fa";
import NextDoseTime from "./NextDoseTime";
import { inject, observer } from "mobx-react";
const DoseCard = observer((props) => {
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#272D5C",
      }}
    >
      <Card.Body
        className={"row m-0 pl-4 pr-4"}
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "24px",
          color: "white",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FaTablets
          size={50}
          className="col-12 col-md-4 col-lg-3 col-xl-2 justify-content-center"
        />
        <span className="col text-center justify-content-center">
          {props.data.time ? "NEXT DOSE IN" : "NO UPCOMING DOSE"}
        </span>
        <NextDoseTime
          date={props.data.time}
          className="col-12 col-xl-4 justify-content-center"
          isDose={true}
        />
      </Card.Body>
    </Card>
  );
});

export default DoseCard;
