import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import ProfileChip from "../components/ProfileChip";
import { Colours } from "../Colours";
import { inject, observer } from "mobx-react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import ToggleSwitch from "../components/ToggleSwitch";
const ProfileContactDetails = observer(({ data, onSettingsChange }) => {
  console.log(data)
  if (!data.settings) {

    data.settings = {
      repeatReminders: true,
      eodReminders: false,
      followUp: "Daily"
    }
  }
  if (!data.options) {
    data.options = ["op1", "op2", "op3"]
  }
  let history = useHistory();
  const [settings, setsettings] = useState(data.settings)
  return (
    <Card
      // className={"col-12 col-lg-4 mr-lg-4"}
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body>
        <Row gutter={(8, 8)} align="middle">
          <Col span={16}>
            <ProfileChip
              name={data.profile.name || "Peter Parker"}
              avatar={data.profile.avatar || null}
              supportingText={
                (data.profile.age || 44) +
                ", " +
                (data.profile.gender || "Male")
              }
              allowEdit={false}
              entity="contact"
            />
          </Col>
          <Col span={8}>
            <Button
              size="lg"
              style={{
                borderRadius: 30,
                fontFamily: "Nunito-Regular",
                fontSize: 18,
                color: "white",
                backgroundColor: Colours.green,
                border: "none",
                // boxShadow: "none",
              }}
              onClick={() => history.push(`/meeting`)}
            >
              Setup a Video Call
            </Button>
          </Col>
          <Col span={10}>
            <ToggleSwitch
              defaultChecked={data.settings.repeatReminders}
              label={"Repeat Reinders"}
              className={"mt-2 mb-3 justify-content-between "}
              onChange={(checked) => {
                var temp = settings;
                temp.repeatReminders = checked;
                setsettings({ ...temp })
                onSettingsChange(settings)
              }}
            />
            <ToggleSwitch
              defaultChecked={data.settings.eodReminders}
              label={"End of Day reminders"}
              className={"mt-3 justify-content-between "}
              onChange={(checked) => {
                var temp = settings;
                temp.eodReminders = checked;
                setsettings({ ...temp })
                onSettingsChange(settings)
              }}
            />
          </Col>
          <Col span={14}>
            <Row>
              <span
                style={{
                  color: Colours.maroon,
                  fontFamily: "Nunito-SemiBold",
                  fontSize: 18,
                }}
              >
                Preferred Pharmacy
              </span>
              <Form.Control
                as="select"
                type="select"
                size="md"
                className={"col-12 my-3"}
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: 20,
                  justifyContent: "center",
                  fontFamily: "Nunito-Regular",
                  fontSize: 19,
                  color: Colours.primaryText,
                }}
              >
                {data.options.map((val, i) => {
                  return (<option key={i} value={val}>
                    {val}
                  </option>)
                })}


                {/* {store.pharmacies.map((item, ind) => {
                      return (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })} */}
              </Form.Control>
            </Row>
            <Row>
              <span
                style={{
                  color: Colours.maroon,
                  fontFamily: "Nunito-SemiBold",
                  fontSize: 18,
                }}
              >
                Technician Follow UP
              </span>
              <div
                className=" mx-0 my-2"
                style={{ justifyContent: "space-between" }}
              >
                {["Daily", "Weekly", "End of Prescription"].map((val, ind) => {
                  return (
                    <Form.Check
                      key={ind + 1}
                      inline
                      label={val}
                      name="followUpDuration"
                      type={"radio"}
                      defaultChecked={val === data.settings.followUp}
                      style={{
                        color: Colours.primaryText,
                        fontFamily: "Nunito-Regular",
                        fontSize: 13,
                      }}
                      onClick={() => {
                        var temp = settings;
                        temp.followUp = val;
                        setsettings({ ...temp })
                        onSettingsChange(settings)

                      }}
                    />
                  );
                })}

              </div>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
});
export default ProfileContactDetails;
