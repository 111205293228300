import { Col, Row, Layout, Button } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ComplianceBubble from "./ComplianceBubble";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
// import { Button } from "bootstrap";
import { Colours } from "../Colours";
const RxInfo = observer(({ data }) => {
  let history = useHistory();
  if (!data) {
    data = {
      rxno: 12345,
      name: "OEDFCJF fapejpj",
      docName: "oeianv oaefao",
      docid: "waopevsjvpadjvapo",
      pcAvg: 20,
    };
  }
  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        padding: 20,
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "white",
      }}
    >
      <Col style={{ fontSize: 16 }}>
        <p>Rx Number: {data.rxno}</p>
        <p>Prescribed By: {data.docName}</p>
      </Col>
      <Col>
        <ComplianceBubble percentage={data.pcAvg} compScale={0.5} />
      </Col>
      <Col>
        <Button
          style={{
            backgroundColor: Colours.green,
            borderRadius: 30,
            // width: 100,
            color: "white",
          }}
          size="large"
          onClick={() => {
            history.push(`/meeting`);
          }}
        >
          Ask a Question
        </Button>
      </Col>
    </Row>
  );
});
export default RxInfo;
