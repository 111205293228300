import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import PersonalInfo from "../components/Personalnfo";
import ProfileChip from "../components/ProfileChip";
import { MdEmail } from "react-icons/md";
import { FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa";
import { observer } from "mobx-react";

const ProfilePersonalDetails = observer((props) => {
  return (
    <>
      <Card
        // className={"col-12 col-lg-4 mr-lg-4"}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          borderRadius: 30,
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        }}
      >
        <Card.Body>
          <Row gutter={(8, 8)} align="middle">
            <Col span={24}>
              {/* {console.log(props)} */}
              <ProfileChip
                name={props.data.name}
                avatar={props.data.avatar}
                supportingText={props.data.age + ", " + props.data.gender}
                allowEdit
                entity="user"
                onEdit={props.uploadProfilePic}
              />
            </Col>
            <Col span={24}>
              <PersonalInfo
                FIcon={MdEmail}
                textContent={props.data.email}
                iconSize={20}
              />
            </Col>
            <Col span={24}>
              <PersonalInfo
                FIcon={FaPhoneAlt}
                textContent={props.data.phone}
                iconSize={20}
              />
            </Col>
            <Col span={24}>
              <PersonalInfo
                FIcon={FaMapMarkedAlt}
                textContent={props.data.address}
                iconSize={20}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
});
export default ProfilePersonalDetails;
