import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { dbstore } from "../dbStore";
import { profileActionStore } from "../actions/profileActionStore";
export default class Store {
  comp1 = { name: "name", age: "age", gender: "gender", email: "email" };
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  comp6 = {};
  constructor() {
    this.dbstore = dbstore;
    this.profilActioneStore = profileActionStore;
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (this.dbstore.patientDetails) {
      var name = this.dbstore.patientDetails.name;
      var age = this.dbstore.patientDetails.age
        ? this.dbstore.patientDetails.age
        : 36;
      var gender = this.dbstore.patientDetails.gender;
      var email = this.dbstore.patientDetails.email;
      var phone = this.dbstore.patientDetails.phone;
      var address = this.dbstore.patientDetails.address.City;
      Object.assign(this.comp1, {
        name: name,
        age: age,
        gender: gender,
        email: email,
        phone: phone,
        address: address,
        avatar: this.dbstore.patientDetails.avatar,
        signature: this.dbstore.patientDetails.signature,
      });
    }
    // console.log(this.comp1);
    return this.comp1;
  }
  get comp2Data() {
    var obj = {
      profile: {},
      options: [dbstore?.pharmacyDetails?.name],
      settings: dbstore?.patientDetails?.settings
    }
    Object.assign(this.comp2, obj)
    return this.comp2;
  }
  get comp3Data() {
    var data = {
      vitalsAverage: {
        bloodPressure: {
          name: "Blood Pressure",
          systolic: 0,
          diastolic: 0,
          units: "cmHg",
        },
        bloodGlucose: {
          name: "Blood Glucose",
          value: 0,
          units: "mgdl",
        },
        temperature: {
          name: "Temperature",
          value: 0,
          units: "F",
        },
        weight: {
          name: "Weight",
          value: 0,
          units: "lbs",
        },
        oxygenLevels: {
          name: "Oxygen Levels",
          value: 0,
          units: "mmHg",
        },
      },
    };
    if (this.dbstore.reports) {
      data = {
        vitalsAverage: {
          bloodPressure: {
            name: "Blood Pressure",
            systolic: Math.floor(this.dbstore.reports.vitalsAvg[3]?.systolic || 0),
            diastolic: Math.floor(this.dbstore.reports.vitalsAvg[3]?.diastolic || 0),
            units: "cmHg",
          },
          bloodGlucose: {
            name: "Blood Glucose",
            value: Math.floor(this.dbstore.reports.vitalsAvg[2]?.value || 0),
            units: "mgdl",
          },
          temperature: {
            name: "Temperature",
            value: Math.floor(this.dbstore.reports.vitalsAvg[1]?.value || 0),
            units: "F",
          },
          weight: {
            name: "Weight",
            value: Math.floor(this.dbstore.reports.vitalsAvg[0]?.value || 0),
            units: "lbs",
          },
          oxygenLevels: {
            name: "Oxygen Levels",
            value: Math.floor(this.dbstore.reports.vitalsAvg[4]?.value || 0),
            units: "mmHg",
          },
        },
      };
    }

    Object.assign(this.comp3, data);
    return this.comp3;
  }
  get comp4Data() {
    return this.comp4;
  }
  get comp5Data() {
    return this.comp5;
  }
  get comp6Data() {
    return this.comp6;
  }
}

const profileStore = new Store();
export { profileStore };
