import { FormControl, InputGroup } from "react-bootstrap";
import ChatListItem from "./ChatListItem";
import { FiSearch } from "react-icons/fi";
import { observer } from "mobx-react";

const ChatList = observer(({ data }) => {
  if (!data) {
    data = {
      arr: []
    }
  }
  return (
    <>
      <div class="col-4 col-lg-3 px-0 bg-white">
        <div
          className="py-2 px-1 bg-white"
          style={{ border: "1px solid #F1F1F3" }}
        >
          <InputGroup className="m-0 bg-white" style={{ height: 50 }}>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white" id="basic-addon1">
                <FiSearch />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              className="border-0"
              placeholder="Search Messages or Name..."
              aria-label="search"
              aria-describedby="basic-addon1"
              style={{ height: 50 }}
            />
          </InputGroup>
        </div>

        <div class="messages-box">
          <div class="list-group rounded-0">
            {data.arr.map((item, index) => {
              return <><ChatListItem
                image={
                  "https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg"
                }
                name={item.name}
                date={"25 Dec"}
                description={
                  "Lorem ipsum dolor sit amet, consectetur. incididunt ut labore."
                }
              /></>;
            })}


          </div>
        </div>
      </div>
    </>
  );
});
export default ChatList;
