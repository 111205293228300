import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { RiSoundModuleLine } from "react-icons/ri";

export default class domainStore {
  value = 15;
  constructor() {
    makeAutoObservable(this);
    //this.value = value;
  }
  get doubleVal() {
    if (this.value % 2 == 0) {
      return 0;
    } else {
      return 10;
    }
    return this.value * 2;
  }
}

const store = new domainStore();
export { store };
