import { inject, observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import useCountDown from "react-countdown-hook";
import moment from "moment";
import { Spin } from "antd";

const NextDoseTime = observer((props) => {
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(
    props.date ? props.date : 0
  );
  useEffect(() => {
    start(props.date);
    console.log(props.date);
  }, [start, props.date]);
  return (
    <div
      className={props.className}
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <span
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "70px",
          color: "white",
          lineHeight: "1",
        }}
      >
        {/* {isDose ? formatTime(timeLeft) : 15} */}
        {props.date ? formatTime(timeLeft) : ""}
      </span>
      <span
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "36px",
          color: "white",
          lineHeight: "1rem",
        }}
      >
        {/* {isDose ? formatUnits(timeLeft) : "days"} */}
        {props.date ? formatUnits(timeLeft) : ""}
      </span>
    </div>
  );
});

const formatTime = (time) => {
  if (time === Infinity) {
    return "-";
  }
  let t = moment(time).fromNow(true).split(" ")[0];
  if (t === "a" || t === "an") {
    return 1;
  } else {
    if (t == "Invalid") {
      return "";
    }
    return t;
  }
};

const formatUnits = (time) => {
  if (time === Infinity) {
    return "";
  }
  let long = moment(time).fromNow(true).split(" ");
  if (long[1] == "date" || long[2] == "date") {
    return "loading..";
  }
  if (long.length > 2) return long[2];
  else return long[1];
};

export default NextDoseTime;
