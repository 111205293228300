import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import ContactButtons from "./ContactButtons";
import ProfileChip from "./ProfileChip";
const ContactCard = observer((props) => {
  var data = props.data;
  return (
    <Row
      style={{
        marginTop: "50px",
        fontFamily: "Nunito-bold",
        marginBottom: "10px",
        borderRadius: 30,
        padding: 10,
        border: "none",
        backgroundColor: "white",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
      align="middle"
    >
      <Col span={24} style={{ textAlign: "center" }}>
        <h6>Contact Your {data?.type}</h6>
      </Col>
      <Col span={16}>
        <ProfileChip
          name={data.name ? data.name : "Peter parker"}
          supportingText={"48, Male"}
          allowEdit={false}
          entity="contact"
        />
      </Col>
      <Col span={8}>
        <ContactButtons />
      </Col>
    </Row>
  );
});
export default ContactCard;
