import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { Colours } from "../Colours";

const CustomizedSteppers = () => {
  function getSteps() {
    return [
      "Refill is Confirmed",
      "Pill Pack Prepared",
      "Your Rx is Shipped",
      "Your Rx is Delivered",
    ];
  }

  const styles = {
    root: {
      width: "100%",
    },
    itemValue: {
      fontFamily: "Nunito-Regular",
      fontWeight: "bold",
      fontSize: 18,
      color: Colours.primaryText,
    },
    itemHeading: {
      fontFamily: "Nunito-Regular",
      fontSize: 18,
      color: Colours.primaryText,
    },
  };
  const [activeStep /*, setActiveStep*/] = useState(1);
  const steps = getSteps();

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <div style={styles.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomizedSteppers;
