import { Colours } from "../Colours";
import ChatMessage from "./ChatMessage";
import { observer } from "mobx-react";

const ChatBox = observer(({ data }) => {
  if (!data) {
    data = { arr: [] }
  }
  return (
    <>
      <div class="col-8 col-lg-9 px-0">
        <ChatHeader
          image="https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg"
          name={"John Doe"}
          designation={"Technician"}
          description={"Refill Request Compliance form"}
        />
        <div class="px-4 py-5 chat-box bg-white">
          {data.arr.map((item, index) => {
            return <><ChatMessage
              source={item.source}
              message={item.message}
              time={item.time}
              date={item.date}
            /></>;
          })}



        </div>

        {/* <!-- Typing area --> */}
        <div class="input-group">
          <input
            type="text"
            placeholder="Type a message"
            aria-describedby="button-addon2"
            class="form-control rounded-0 border-0 py-4 bg-light"

            onKeyUp={(e) => {
              if (e.key == 'Enter') {

                data.submit(e.target.value)
                e.target.value = ''
              }
            }}
          />
          <div class="input-group-append">
            <button id="button-addon2" type="submit" class="btn btn-link">
              {" "}
              <i class="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

const ChatHeader = ({ image, name, designation, description }) => {
  return (
    <>
      <div
        className="col-12 w-100 bg-white py-2 row m-0"
        style={{ borderBottom: "1px solid #F1F1F3" }}
      >
        <img src={image} alt="user" width="50" class="rounded-circle" />
        <div className="col col-md-4">
          <div
            style={{
              fontFamily: "SourceSansPro-Regular",
              fontSize: 18,
              color: Colours.secondaryText,
            }}
          >
            {name}
          </div>
          <div
            style={{
              fontFamily: "SourceSansPro-Regular",
              fontSize: 15,
              color: Colours.secondaryText,
            }}
          >
            {designation}
          </div>
        </div>
        <div
          className="col justify-content-center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontFamily: "SourceSansPro-Regular",
              fontWeight: "bold",
              fontSize: 20,
              color: Colours.primaryText,
            }}
          >
            {description}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
