import { Layout, message } from "antd";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Col, Row, Card, Form, Button } from "react-bootstrap";
import { Colours } from "../Colours";
const MeetingForm = observer(({ onSubmit = () => { } }) => {

  const [formData, setformData] = useState({
    date: null,
    timeSlot: "A",
    description: "",
  });
  return (
    <div className={"col px-0  mt-4"}>
      <Card
        style={{
          marginTop: "50px",
          marginBottom: "10px",
          borderRadius: 30,
          border: "none",
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        }}
      >
        <Card.Body className="d-flex flex-column">
          <div
            className="my-2"
            style={{
              fontFamily: "Nunito-Bold",
              fontSize: 26,
              color: Colours.primaryText,
              textAlign: "center",
            }}
          >
            Schedule a Video Call with a Technician or a Pharmacist
          </div>
          <Form

            className="my-4"
            style={{
              fontFamily: "SourceSansPro-Regular",
              fontSize: 15,
              color: Colours.primaryText,
            }}
          >
            <Form.Group as={Row} controlId="date" style={{ marginBottom: 20 }}>
              <Form.Label
                column
                sm="4"
                style={{ textAlign: "right" }}
                className="text-right"
              >
                Select Date:
              </Form.Label>
              <Col sm="8" lg="6">
                <Form.Control
                  type="date"

                  value={formData.date}
                  onChange={(e) => {
                    var temp = formData;

                    temp["date"] = e.target.value;
                    console.log(temp);
                    setformData({ ...temp });
                  }}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              controlId="timeSlot"
              style={{ marginBottom: 20 }}
            >
              <Form.Label
                column
                sm="4"
                style={{ textAlign: "right" }}
                className="text-right"
              >
                Preferred Time Slots:
              </Form.Label>
              <Col sm="8" lg="6">
                <Form.Control
                  as="select"
                  value={formData.timeSlot}
                  onChange={(e) => {
                    var temp = formData;

                    temp["timeSlot"] = e.target.value;
                    console.log(temp);
                    setformData({ ...temp });
                  }}
                >
                  <option value="A">10 AM - 10:30 AM</option>
                  <option value="B">10:30 AM - 11:00 AM</option>
                  <option value="C">11 AM - 11:30 AM</option>
                  <option value="D">11:30 AM - 12:00 PM</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              controlId="description"
              style={{ marginBottom: 20 }}
            >
              <Form.Label
                column
                sm="4"
                style={{ textAlign: "right" }}
                className="text-right"
              >
                Describe Your Issue:
              </Form.Label>
              <Col sm="8" lg="6">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Description of the Issue"
                  value={formData.description}
                  onChange={(e) => {
                    var temp = formData;

                    temp["description"] = e.target.value;

                    setformData({ ...temp });
                  }}
                />
              </Col>
            </Form.Group>
            <Row className="justify-content-center">
              <Button
                variant="success px-4 py-2"
                style={{
                  backgroundColor: Colours.green,
                  borderRadius: 30,
                  width: 100,
                }}
                onClick={async () => {
                  if (!formData.description && formData.description == "") {
                    message.error("Please decribe your issue");
                    return;
                  }
                  if (!formData.date) {
                    message.error("Please select a date");
                    return;
                  }
                  onSubmit(formData);
                  setformData({ date: "", timeSlot: "A", description: "" });
                }}
              >
                Submit
              </Button>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
});
export default MeetingForm;
