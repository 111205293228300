import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import moment from "moment";
import { dbstore } from "../dbStore";
import { prescriptionsStore } from "../pages/prescriptionsStore";

export default class Store {
  comp1 = { selectedDocid: null };

  comp2 = {
    date: moment().startOf("day").format("x"),
    showSelect: false,
    selectedTime: null,
  };
  comp3 = {};
  constructor() {
    this.dbstore = dbstore;

    makeAutoObservable(this, {
      setComp1: action,
      setComp2: action,
      setComp3: action,
    });
  }

  setComp2(obj) {
    Object.assign(this.comp2, obj);
  }
  setDocid(docid) {
    this.comp1.selectedDocid = docid;
    // Object.assign(this.comp1, { selectedDocid: docid });
  }
  setPrescriptionStatus(rxid, selected) {
    var i = _.findIndex(prescriptionsStore.comp2Data.pillsList, { rxid: rxid });
    prescriptionsStore.comp2.pillsList[i]["selected"] = selected;
  }
  *updatePrescription(type) {
    var arr = [];


    prescriptionsStore.comp2Data.pillsList.forEach((pc) => {
      var obj = {
        date: pc.date,
        time: pc.time,
        rxid: pc.rxid,
        status:
          type == "yes"
            ? pc.selected
              ? "taken"
              : "missed"
            : pc.selected
              ? "missed"
              : "taken",
      };
      arr.push(obj);
    });
    dbstore.updatePrescription(arr)
  }
}

const prescriptionsActionStore = new Store();
export { prescriptionsActionStore };
