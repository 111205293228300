import { Col, Row, Layout, message } from "antd";
import { useEffect, useState } from "react";
import { dbstore } from "../../dataLayer/stores/dbStore";
import PrescriptionTransferForm from "../PrescriptionTransferForm";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const PrescriptionTransfer = ({ props }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <PrescriptionTransferForm onSubmit={async (obj) => {
              console.log(obj);
              var status = await dbstore.newPT(obj);
              if (status == 200) {
                message.success("Prescription Transfer request submitted")
              } else {
                message.error("Oops! something went wrong please try again")
              }
            }} />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PrescriptionTransfer;
