import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
// import { parseToBool } from "../Common.fns";
import { Row, Col } from "antd";

const ComplianceStatusCard = ({ compliance, className, onClick, compKey }) => {
  const names = {
    hipaa: {
      name: "Hippa",
    },
    prescriptionTransfer: {
      name: "Prescription Transfer",
    },
    autoRefill: {
      name: "Auto Refill",
    },
    autoPay: {
      name: "Auto Pay",
    },
    autoDelivery: {
      name: "Auto Delivery",
    },
    digitalSignature: {
      name: "Digital Signature",
    },
    pillPackEnrollment: {
      name: "Pill Pack Enrollment",
    },
  };
  return (
    <Col span={8}>
      <div
        style={{
          borderRadius: "20px",
          width: 120,
          height: 85,
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          backgroundImage: compliance
            ? "linear-gradient(to bottom right, #28F0AF, #147858)"
            : "linear-gradient(to bottom right, #FF4B1F, #FA5C56)",
          cursor: "pointer",
        }}
        onClick={() => {
          onClick(compKey);
        }}
      >
        <div
          className={"col m-0 p-0"}
          style={{
            height: "100%",
            width: "100%",
            fontFamily: "Nunito-Light",
            fontSize: "12px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {compliance ? (
            <IoCheckmarkCircleOutline size={30} />
          ) : (
            <IoCloseCircleOutline size={30} />
          )}
          <span className="text-center">{names[compKey].name}</span>
        </div>
      </div>
    </Col>
  );
};

export default ComplianceStatusCard;
