const CircleButton = ({ children, store, style, onClick }) => {
  const styles = {
    round: {
      display: "flex",
      height: 40,
      width: 40,
      borderRadius: 20,
      justifyContent: "center",
      textAlign: "center",
      flexDirection: "row",
      alignItems: "center",
    },
  };
  return (
    <div style={{ ...styles.round, ...style }} onClick={onClick}>
      {children}
    </div>
  );
};

export default CircleButton;
