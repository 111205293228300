// import { Layout } from "antd";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Col, Row, Card, Form, Button } from "react-bootstrap";
import { Colours } from "../Colours";
import { MdFileUpload } from "react-icons/md";
import { message } from "antd"
import "bootstrap/dist/css/bootstrap.min.css";

const PrescriptionTransferForm = observer(({ onSubmit = () => { } }) => {
  const formRef = useRef();
  const modalRef = useRef();
  const fileNameRef = useRef();
  const [formData, setformData] = useState({
    previousPharmacy: '',
    prescribedDoctor: "",
    rxno: "",
    file: null
  });
  return (
    <Card
      style={{
        marginTop: "50px",
        marginBottom: "10px",
        borderRadius: 30,
        border: "none",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <Card.Body className="d-flex flex-column">
        <div
          className="my-2"
          style={{
            fontFamily: "Nunito-Bold",
            fontSize: 26,
            color: Colours.primaryText,
            textAlign: "center",
          }}
        >
          Upload Your Prescription to Transfer it.
        </div>
        <Form
          ref={formRef}
          className="my-4"
          style={{
            fontFamily: "SourceSansPro-Regular",
            fontSize: 15,
            color: Colours.primaryText,
          }}
        >
          <Form.Group
            as={Row}
            style={{ marginBottom: 20 }}
            controlId="rxNumber"
          >
            <Form.Label
              column
              sm="4"
              style={{ textAlign: "right" }}
              className="text-right"
            >
              Rx Number:
            </Form.Label>
            <Col sm="8" lg="6">
              <Form.Control type="text" placeholder="Rx Number" value={formData.rxno} onChange={(e) => {
                var temp = formData;

                temp["rxno"] = e.target.value;
                console.log(temp);
                setformData({ ...temp });
              }} required />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            style={{ marginBottom: 20 }}
            controlId="prescribedBy"
          >
            <Form.Label
              column
              sm="4"
              style={{ textAlign: "right" }}
              className="text-right"
            >
              Prescribed By Doctor:
            </Form.Label>
            <Col sm="8" lg="6">
              <Form.Control type="text" placeholder="Doctor Name" value={formData.prescribedDoctor}
                onChange={(e) => {
                  var temp = formData;

                  temp["prescribedDoctor"] = e.target.value;
                  console.log(temp);
                  setformData({ ...temp });
                }}
                required />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            style={{ marginBottom: 20 }}
            controlId="previousPharmacy"
          >
            <Form.Label
              column
              sm="4"
              style={{ textAlign: "right" }}
              className="text-right"
            >
              Previous Pharmacy:
            </Form.Label>
            <Col sm="8" lg="6">
              <Form.Control type="text" placeholder="Pharmacy Name" value={formData.previousPharmacy} onChange={(e) => {
                var temp = formData;

                temp["previousPharmacy"] = e.target.value;
                console.log(temp);
                setformData({ ...temp });
              }} required />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            style={{ marginBottom: 20 }}
            controlId="prescription"
          >
            <Form.Label
              column
              sm="4"
              style={{ textAlign: "right" }}
              className="text-right"
            >
              Upload Prescription Copy:
            </Form.Label>
            <Col sm="8" lg="6">
              <Form.File>
                <Form.File.Label htmlFor="prescriptionPhoto">
                  <Row className="m-0 align-items-center">
                    <Row
                      variant="outline-secondary"
                      className="round px-1 py-2  mx-0 mr-3"
                      style={{
                        borderRadius: 10,
                        borderColor: "gray",
                        borderWidth: 1,
                        borderStyle: "solid",
                        width: 140,
                      }}
                      type="button"
                    >
                      <Col xs={2} style={{ marginRight: 10 }}>
                        <MdFileUpload color="black" size={25} />
                      </Col>
                      <Col>
                        <span>Upload File</span>
                      </Col>
                    </Row>
                    <span ref={fileNameRef}></span>
                  </Row>
                </Form.File.Label>
                <Form.File.Input
                  id="prescriptionPhoto"
                  className="invisible"
                  accept="image/*"
                  onChange={(e) => {
                    let arr = e.target.value.split("\\");
                    fileNameRef.current.innerText = arr[arr.length - 1];
                    console.log(e.target.files[0])

                    var temp = formData;

                    temp["file"] = e.target.files[0];
                    console.log(temp);
                    setformData({ ...temp });

                  }}
                  required
                />
              </Form.File>
            </Col>
          </Form.Group>
          <Row className="justify-content-center">
            <Button
              variant="success px-4 py-2"
              style={{
                backgroundColor: Colours.green,
                borderRadius: 30,
                width: 100,
              }}
              onClick={async () => {

                if (!formData.rxno || formData.rxno == '') {
                  message.error("Please enter rxno")
                  return
                }
                if (!formData.prescribedDoctor || formData.prescribedDoctor == '') {
                  message.error("Please enter prescribed Doctor")
                  return
                }
                if (!formData.previousPharmacy || formData.previousPharmacy == '') {
                  message.error("Please enter previous pharmacy")
                  return
                }
                if (!formData.file) {
                  message.error("Please select an image")
                  return
                }

                onSubmit(formData);
                setformData({
                  previousPharmacy: '',
                  prescribedDoctor: "",
                  rxno: "",
                  file: null
                })

                // console.log(formRef.current.prescriptionPhoto.files[0]);
                // try {
                //   let isValid = checkEmpty(formRef.current, [
                //     "rxNumber",
                //     "previousPharmacy",
                //     "prescribedBy",
                //     "prescriptionPhoto",
                //   ]);
                //   if (isValid) {
                //     let payload = {
                //       phid: store.preferredPharmacy._id,
                //       trxid: store.preferredTechnician._id,
                //       rxno: formRef.current.rxNumber.value,
                //       previouspharmacy: formRef.current.previousPharmacy.value,
                //       prescribeddoctor: formRef.current.prescribedBy.value,
                //     };
                //     console.log(payload);
                //     let data = await store.prescriptionTransfer(
                //       payload,
                //       formRef.current.prescriptionPhoto.files[0]
                //     );
                //     console.log(data);
                //     modalRef.current.toggleModal(data.status === 200);
                //   } else {
                //     formModalRef.current.toggleModal(false);
                //   }
                // } catch (error) {
                //   console.error(error);
                // }
              }}
            >
              Submit
            </Button>
          </Row>
        </Form>
      </Card.Body>
      {/* <ActionFeedbackModal
        myRef={modalRef}
        successMessage="Your Prescription Transfer Request is successfully submitted."
        failureMessage="Your Prescription Transfer Request failed."
      />
      <ActionFeedbackModal
        myRef={formModalRef}
        failureMessage="Please enter all the details."
      /> */}
    </Card>
  );
});
export default PrescriptionTransferForm;
