import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
} from "mobx";
import { dbstore } from "../dbStore";
import _ from "lodash";
import { prescriptionsActionStore } from "../actions/prescriptionsActionStore";

export default class Store {
  comp1 = { rxarr: [] };
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var rxDocGroup = _.groupBy(dbstore.rxarr, "docid");

    var rx = prescriptionsActionStore.comp1.selectedDocid
      ? rxDocGroup[prescriptionsActionStore.comp1.selectedDocid]
      : [];
    var arr = [];
    rx.forEach((r) => {
      arr.push({
        rxno: r.rxno,
        refills: r.refills.length,
        name: r.name,
        cdate: r.expirydate,
      });
    });
    var obj = {
      // docid: prescriptionsActionStore.comp1.selectedDocid,
      rxarr: arr,
    };
    // this.comp1 = [];
    Object.assign(this.comp1, obj);
    // this.comp1 = arr;

    return this.comp1;
  }
}

const rxListStore = new Store();
export { rxListStore };
