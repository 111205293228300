import { Col, Row, Layout, Button } from "antd";
import { Step, StepLabel, Stepper, Switch } from "@material-ui/core";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Colours } from "../Colours";
import moment from "moment";
import ToggleSwitch from "./ToggleSwitch";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
const RxInfo2 = observer(({ data }) => {
  let history = useHistory();
  if (!data) {
    data = {
      refills: 4,
      currentRefill: 2,
      currentRefillStart: moment().format("DD MMMM YYYY"),
      currentRefillEnd: moment().format("DD MMMM YYYY"),
      currentStatus: "Pillpack Available",
      autoRefill: true,
      lastFillDate: moment().format("DD MMMM YYYY"),
    };
  }
  const styles = {
    root: {
      width: "100%",
    },
    itemValue: {
      fontFamily: "Nunito-Regular",
      fontWeight: "bold",
      fontSize: 18,
      color: Colours.primaryText,
    },
    itemHeading: {
      fontFamily: "Nunito-Regular",
      fontSize: 18,
      color: Colours.primaryText,
    },
  };
  return (
    <>
      <Row
        gutter={[16, 16]}
        align="middle"
        style={{
          padding: 20,
          borderRadius: 30,
          boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          backgroundColor: "white",
        }}
      >
        <Col span={24}>
          <Stepper alternativeLabel activeStep={data.currentRefill}>
            {Array(data.refills)
              .fill(0)
              .map((i, c = 0) => {
                c++;
                return (
                  <Step key={"label"}>
                    <StepLabel>{"Refill " + c}</StepLabel>
                  </Step>
                );
              })}
          </Stepper>
        </Col>
        <Col span={24}>
          <div
            style={{
              borderRadius: 30,
              margin: 20,
              textAlign: "center",
              backgroundColor: "#f5f5f5",
              fontSize: 18,
              fontFamily: "Nunito-Regular",
              fontWeight: "bold",
              padding: 5,
            }}
          >
            <p>
              This prescription is live for 6 months and your next refill is in
              20 days.
            </p>
          </div>
        </Col>
        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            Start Date
          </div>
          <div className="text-center" style={styles.itemValue}>
            {data.currentRefillStart || "N/A"}
          </div>
        </Col>
        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            End Date
          </div>
          <div className="text-center" style={styles.itemValue}>
            {data.currentRefillEnd || "N/A"}
          </div>
        </Col>
        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            Refills
          </div>
          <div className="text-center" style={styles.itemValue}>
            {data.refills - data.currentRefill || "N/A"}
          </div>
        </Col>

        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            Current Status
          </div>
          <div className="text-center" style={styles.itemValue}>
            {data.currentStatus || "N/A"}
          </div>
        </Col>
        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            Auto Refill
          </div>
          <div>
            <ToggleSwitch
              defaultChecked={data.autoRefill}
              onChange={(checked) => { }}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="text-center" style={styles.itemHeading}>
            Last Fill
          </div>
          <div className="text-center" style={styles.itemValue}>
            {data.lastFillDate || "N/A"}
          </div>
        </Col>
        <Col span={24}>
          <div style={{ width: 170, marginLeft: "auto", marginTop: 30 }}>
            <Button
              style={{
                backgroundColor: Colours.green,
                borderRadius: 30,
                // width: 100,
                color: "white",
              }}
              size="large"
              onClick={() => {
                history.push(`/prescriptiontransfer`);
              }}
            >
              Upload Prescription
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default RxInfo2;
