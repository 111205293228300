import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Card, Button, Table } from "react-bootstrap";
import { Colours } from "../Colours";
import moment from "moment";

const { Column, ColumnGroup } = Table;
const RxListTable = observer(({ data, onClick }) => {
  console.log(data);
  if (!data) {
    data = [
      {
        rxno: 12345,
        name: "Bhaajkn auai",
        refills: 9,
        cdate: new Date(),
      },
    ];
  }

  const tableTitles = [
    "Rx Number",
    "Medicine Name",
    "Refills",
    "Completion Date",
  ];
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        padding: 20,
        // minHeight: 300,
      }}
    >
      <h5>Medicine's List</h5>
      <Table
        responsive
        className="mb-0"
        style={{
          fontFamily: "SourceSansPro-Regular",
          fontSize: 13,
          color: Colours.secondaryText,
        }}
      >
        <thead
          style={{
            border: "none",
            backgroundColor: "#F5F6FA",
            color: "#A3A6B4",
          }}
        >
          <tr style={{ textTransform: "uppercase" }}>
            {tableTitles.map((title, index) => (
              <th key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((rx, ind) => {
            return (
              <tr
                key={rx.rxno}
                onClick={() => {
                  onClick(rx.rxno);
                }}
                style={{
                  cursor: "pointer",
                  height: 50,
                }}
              >
                <td>{rx.rxno}</td>
                <td>{rx.name}</td>
                <td>{rx.refills}</td>
                <td>{moment(rx.cdate).format("DD, MMM, YYYY")}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data.length == 0 && (
        <h6 style={{ textAlign: "center", padding: 20 }}>No Medicine's</h6>
      )}
    </Card>
  );
});
export default RxListTable;
