import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
  runInAction,
} from "mobx";
import moment from "moment";
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
import Client from 'twilio-chat';
import { message } from "antd";


const fetch = require("node-fetch");
//const endpoint = "https://api.doctorspharmacy.us";
const endpoint = process.env.REACT_APP_SERVER_URL

export default class dbStore {
  endpoint = null;
  loading = true;
  login = false;
  userid = "t1881t";
  // userid = "6110142577f4b00b95355291";
  decodedUserId = null//"60dcc4dcfef5466308c7b3dd"// null;

  patientDetails = null;
  pharmacyDetails = null;
  rxarr = null;
  pillcompliancePercentage=0;
  pcarr = null;
  pcarrDaily = null;
  reports = null;
  pillcomplianceAvg = null;
  doctorDetails = null;
  vitalChart = {};
  vitalRecords = [];

  chatToken = null;
  chatClient = null;
  chatChannels = []

  selectedCompliance = null;

  constructor() {
    this.endpoint = endpoint;
    makeAutoObservable(this);
  }

  setSelectedCompliance = (compliance) => {
    this.selectedCompliance = compliance;
  }

  *getData() {
    this.checkSession()
    console.log("getting data");
    this.refreshStore();
    console.log(this.login)
    if (this.login) {
      console.log('entered', this.login)
      yield this.getPatientData()
      // var res = yield fetch(
      //   `${endpoint}/patients?cnd={"_id":"${this.userid}"}&prj={"vitals":0,"_id":0}`,
      //   {
      //     method: "get",
      //     // headers: { "Content-Type": "application/json" },
      //   }
      // );
      // res = yield res.json();
      // console.log(res);
      // this.patientDetails = res[0];
      var res = yield fetch(
        `${endpoint}/patients/pharmacies?_id=${this.patientDetails.phid}`,
        {
          method: "get",
          credentials: 'include'
          // headers: { "Content-Type": "application/json" },
        }
      );
      res = yield res.json();
      console.log(res);
      this.pharmacyDetails = res[0];
      res = yield fetch(
        `${endpoint}/patients/rx?patid=${this.userid}`,
        {
          method: "get",
          credentials: 'include',
          cache: 'no-cache'
          // headers: { "Content-Type": "application/json" },
        }
      );
      res = yield res.json();
      this.rxarr = res;
      console.log(res);
      var pcfrom = moment().subtract(3, "days").format("x") + "";
      var pcto = moment().add(3, "days").format("x") + "";
      yield this.getPcArr(pcfrom, pcto);

      res = yield fetch(
        `${endpoint}/patients/pillcompliance/daily?patid=${this.userid}`,
        {
          method: "get",
          credentials: 'include',
          cache: 'no-cache'
          // headers: { "Content-Type": "application/json" },
        }
      );
      res = yield res.json();
      this.pcarrDaily = res;
      console.log(res);

      res = yield fetch(
        `${endpoint}/patients/reports?patid=${this.userid}`,
        {
          method: "get",
          credentials: 'include',
          cache: 'no-cache'
          // headers: { "Content-Type": "application/json" },
        }
      );
      res = yield res.json();
      res = res[0];
      // Object.keys(res).forEach((e) => {
      //   this[e] = res[e];
      // });
      this.reports = res;

      console.log(res, 'reports data');

      yield this.getDoctorDetails();

      // yield this.getChatToken(false)
      // yield this.getChannels();


      this.loading = false;
    }
  }

  *approveComplince(type) {
    this.checkSession()

    if (!type) {
      type = this.selectedCompliance
    }
    this.loading = true;

    var raw = {

      type: type
    }
    console.log(raw)
    var res = yield fetch(
      `${endpoint}/patients/approve/compliance`,
      {
        method: "post",

        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)

      }
    );

    var status = res.status;
    res = yield res.json();
    if (status == 200) {
      message.success('Compliance Approved');
      this.patientDetails.compliance[type].signed = true
      // navigationRef.current.navigate("Home")
    }
    this.loading = false
    return status
  }



  *getPcArr(pcfrom, pcto) {
    this.checkSession()

    // var pcfrom = moment().subtract(3, "days").format("x") + "";
    // var pcto = moment().add(3, "days").format("x") + "";
    var res = yield fetch(
      // `${endpoint}/patients/pillcompliance/pipeline?pipeline=${pipeline}`,
      `${endpoint}/patients/pillcompliance/pipeline?patid=${this.userid}&pcfrom=${pcfrom}&pcto=${pcto}`,
      {
        method: "get",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },

        cache: 'no-cache'
      }
    );
    res = yield res.json();
    console.log(res, 'pcarr data');

    this.pcarr = res.res;
    this.pillcompliancePercentage = (res.aggregations[0].takenCount/res.aggregations[0].totalCount) * 100;
    return res
  }
  *getDoctorDetails(arr) {
    this.checkSession()

    var res = yield fetch(
      `${endpoint}/patients/doctors?userid=${this.userid}`,
      {
        method: "get",
        credentials: 'include',
        cache: 'no-cache'
        // headers: { "Content-Type": "application/json" },
      }
    );
    res = yield res.json();
    // console.log(JSON.stringify(res));
    this.doctorDetails = res;
  }

  *getPatientData() {
    this.checkSession()

    var res = yield fetch(
      `${endpoint}/patients/details?_id=${this.userid}`,
      {
        method: "get",
        cache: "no-cache",
        credentials: 'include'
        // headers: { "Content-Type": "application/json" },
      },

    );
    res = yield res.json();
    console.log(res);
    this.patientDetails = res[0];
  }



  *getVitalRecords(type, limit, page, refresh = false) {
    this.checkSession()

    var options = {
      method: "get",
      credentials: 'include',
      cache: 'no-cache'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options.cache = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}/patients/vitalrecords?patid=${this.userid}&type=${type}&limit=${limit}&page=${page}`,
      options
    );
    res = yield res.json();
    // console.log(JSON.stringify(res));
    console.log(res);

    this.vitalRecords = res.length > 0 ? res : [];
    console.log(this.vitalRecords);
  }

  *newVital(obj) {
    this.checkSession()

    this.loading = true;
    obj.date = moment().format('x')
    var raw = {
      condition: { "_id": this.userid },
      data: { $push: { vitals: obj } }
    }
    var res = yield fetch(
      `${endpoint}/patients`,
      {
        method: "put",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw),
        cache: 'no-cache'
      }
    );
    var status = res.status;
    res = yield res.json();
    console.log(res)
    yield this.getVitalRecords(obj.type, 10, 0, true);
    this.loading = false
    return status;
  }

  *newMeeting(obj) {
    this.checkSession()

    this.loading = true;
    var raw = obj;
    obj["patid"] = this.userid;
    obj["phid"] = this.patientDetails.phid;
    var res = yield fetch(`${endpoint}/patients/meetings`, {
      method: "post",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
      cache: 'no-cache'
    });
    var status = res.status;
    res = yield res.json();
    // console.log(JSON.stringify(res));

    this.loading = false;
    return status;
  }

  *newPT(obj) {
    this.checkSession()

    this.loading = true;
    var raw = obj;
    obj["patid"] = this.userid;
    obj["phid"] = this.patientDetails.phid;
    var file = obj['file']
    delete obj['file']
    var res = yield fetch(`${endpoint}/patients/prescriptionTransfers`, {
      method: "post",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw),
    });
    var status = res.status;
    res = yield res.json();
    console.log(res)
    // console.log(JSON.stringify(res));
    if (status == 200) {
      var s = yield this.uploadFile(res.url, file)
      console.log(s)
      status = s;
    }
    this.loading = false;
    return status;
  }

  *uploadFile(url, file) {
    this.checkSession()

    this.loading = true;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/jpeg");
    var res = yield fetch(url, {
      method: "PUT",
      body: file,
      headers: myHeaders,
      redirect: "follow",
    })

    var status = res.status;
    this.loading = false;

    return status;
  }

  * newProfilePic(file) {
    this.checkSession()

    this.loading = true;
    var raw = { userid: this.userid }
    var res = yield fetch(`${endpoint}/patients/profilepic`, {
      method: "post",
      credentials: 'include',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(raw)
    });
    var status = res.status;
    res = yield res.json();
    console.log(res)
    if (status == 200) {
      var url = res.url

      var s = yield this.uploadFile(url, file)

      console.log(s)
      status = s
    }
    this.loading = false;
    return status;
  }

  *updateSettings(obj) {
    this.checkSession()

    this.loading = true;

    var raw = {
      condition: { "_id": this.userid },
      data: { settings: obj }
    }
    console.log(raw)
    var res = yield fetch(
      `${endpoint}/patients`,
      {
        method: "put",

        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)

      }
    );

    var status = res.status;
    res = yield res.json();
    console.log(res)
    yield this.getPatientData()
    this.loading = false
    return status
  }
  *loginFunc(phone, otp) {
    console.log('login ', phone, otp)

    var raw = {
      phone: phone,
      otp: otp
    }
    var res = yield fetch(
      `${endpoint}/auth/patients/login`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)
      }
    );

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      console.log("login suc")
      this.login = true;
      // console.log(Cookies.get(), '9999999999999')
      yield this.getData()
      // console.log("get data called")
    }
    return status;
  }

  *requestOTP(phone) {
    console.log('login ', phone)

    var raw = {
      phone: phone
    }
    var res = yield fetch(
      `${endpoint}/auth/patients/otp`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',
        body: JSON.stringify(raw)
      }
    );

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      this.loading = false;
    }
    return status;
  }

  *logoutFunc() {

    var res = yield fetch(
      `${endpoint}/auth/patients/logout`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        credentials: 'include',

      }
    );

    var status = res.status;
    res = yield res.json();
    console.log(res, status);
    if (status == 200) {
      console.log("login suc")
      this.login = false;

    }
    return status;
  }

  refreshStore() {
    // var data = JSON.parse(document.cookie)
    console.log(Cookies.get(), 'chh')
    this.login = Cookies.get('plogin') == 'true'
    this.decodedUserId = Cookies.get('ptoken') ? jwt_decode(Cookies.get('ptoken'))._id : null
    console.log(this.decodedUserId, Cookies.get('ptoken'), 'decoded')
  }

  *updatePrescription(arr) {
    this.checkSession()

    this.loading = true;
    var body = { data: arr };
    var res = yield fetch(`${this.endpoint}/patients/pillcompliance/bulk`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",

      },
      credentials: 'include',
      body: JSON.stringify(body),
    });
    res = yield res.json();
    yield this.getPcArr();
    this.loading = false;
  }



  *getChatToken(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/token';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.chatToken = res.jwt;
    console.log(this.chatToken)
    this.chatClient = yield Client.create(this.chatToken);
    this.loading = false;
  }
  *getChannels(refresh = false) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/channel';
    var options = {
      method: "get",

      credentials: 'include'
      // headers: { "Content-Type": "application/json" },
    }
    if (refresh) {
      options['cache'] = "no-cache"
    }
    var res = yield fetch(
      `${endpoint}${path}`,
      options,

    );
    res = yield res.json();
    console.log(res);
    this.chatChannels = res;
    yield this.joinChannel();
    yield this.loadChannelMessages();
    console.log(this.chatChannels)
    this.loading = false;
  }
  configureChannelEvents(channel, ind) {
    channel.on('messageAdded', message => {
      this.chatChannels[ind].messages.items.push(message);
    });
    channel.on('memberJoined', member => {
      console.log('member joined');
      // store.domainStore.addMessage({body: `${member.identity} has joined the channel.`});
    });
    channel.on('memberLeft', member => {
      // store.domainStore.addMessage({body: `${member.identity} has left the channel.`});
    });
  }
  *joinChannel(arr) {
    if (!arr) {
      arr = this.chatChannels;
    }

    for (var i = 0; i < arr.length; i++) {
      let channel = yield this.chatClient.getChannelBySid(arr[i]._id);
      channel =
        channel.channelState.status !== 'joined' ? yield channel.join() : channel;
      this.chatChannels[i].channel = channel
      this.configureChannelEvents(channel, i);
    }


  }
  *loadChannelMessages() {


    for (let i = 0; i < this.chatChannels.length; i++) {
      var m = yield this.chatChannels[i].channel.getMessages();
      this.chatChannels[i].messages = m;
    }
  }

  *createChannel(obj) {
    this.checkSession()

    this.loading = true;
    var path = '/chat/channel'
    var raw = {

      ...obj
    };
    var res = yield fetch(
      `${endpoint}${path}`,
      {
        method: "post",
        // cache: "no-cache",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(raw)
      },

    );
    var status = res.status;
    // res = yield res.json();
    // console.log(res);
    this.loading = false;
    return status;

  }




  checkSession() {
    this.login = Cookies.get('plogin') == 'true'

  }

}

const dbstore = new dbStore();
export { dbstore };
