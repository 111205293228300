import { useState } from "react";
import { Card } from "react-bootstrap";
import { FaTablets, FaTruck } from "react-icons/fa";
import NextDoseTime from "./NextDoseTime";
import { inject, observer } from "mobx-react";
const RefillCard = observer((props) => {
  const [showDispatch, setShowDispatch] = useState(false);
  return (
    <Card
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#A01027",
        cursor: "pointer",
      }}
      onClick={() => setShowDispatch(!showDispatch)}
    >
      <Card.Body
        className={"row m-0 pl-4 pr-4"}
        style={{
          fontFamily: "Nunito-Regular",
          fontSize: "24px",
          color: "white",
          flex: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showDispatch ? (
          <>
            <span
              className="col text-center justify-content-center"
              style={{ fontSize: "18px" }}
            >
              Your Medications Dispatched from pharmacy on <br /> 03/10/2020
            </span>
            {/* <NextDoseTime className="col-12 col-xl-4 justify-content-center" /> */}
            <FaTruck
              size={75}
              className="col-12 col-xl-4 justify-content-center"
            />
          </>
        ) : (
          <>
            <FaTablets
              size={50}
              className="col-12 col-md-4 col-lg-3 col-xl-2 justify-content-center"
            />
            <span className="col text-center justify-content-center">
              {props.data.time ? 'NEXT REFILL IN' : "NO UPCOMING REFILLS"}
            </span>
            <NextDoseTime
              className="col-12 col-xl-4 justify-content-center"
              isDose={false}
              date={props.data.time}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
});

export default RefillCard;
