import { Col, Row } from "react-bootstrap";
import { Colours } from "../Colours";
import ReportStatsCard from "./ReportStatsCard";

const ReportStats = (props) => {
  return (
    <div>
      <ReportStatsCard data={props.data}/>
      <Row className={"justify-content-center"}>
        <Col
          xs={12}
          md={8}
          className={"px-5 py-4"}
          style={{
            fontFamily: "Nunito-Regular",
            fontSize: 32,
            color: Colours.primaryText,
            textAlign: "center",
          }}
        >
          You seem to be missing your pills. Please be compliant and get
          healthier faster.
        </Col>
      </Row>
    </div>
  );
};

export default ReportStats;
