import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { vitalsActionStore } from "../actions/vitalsActionStore";
import { dbstore } from "../dbStore";
import _ from "lodash";
import moment from "moment";
export default class Store {
  vitalMetadata = {
    bloodPressure: {
      name: "Blood Pressure",
      units: "cmHg",
      color: "#FF6773"
    },
    bloodGlucose: {
      name: "Blood Glucose",
      units: "mgdl",
      color: '#7066D1'
    },
    temperature: {
      name: "Temperature",
      units: "F",
      color: '#0C82BA'
    },
    weight: {
      name: "Weight",
      units: "lbs",
      color: '#574FFA'
    },
    oxygenLevels: {
      name: "Oxygen Levels",
      units: "mmHg",
      color: '#ED8F03'
    },
  };
  comp1 = {};
  comp2 = {};
  comp3 = {};
  comp4 = {};
  comp5 = {};
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var obj = {};
    var index = _.findIndex(dbstore.reports?.vitalsAvg || {}, {
      key: vitalsActionStore.selectedVital,
    });
    obj = {
      name: this.vitalMetadata[vitalsActionStore.selectedVital].name,
      color: this.vitalMetadata[vitalsActionStore.selectedVital].color,
      unit: this.vitalMetadata[vitalsActionStore.selectedVital].units,
      key: vitalsActionStore.selectedVital,

      systolic: Math.floor(dbstore.reports?.vitalsAvg[index]?.systolic || 0),
      diastolic: Math.floor(dbstore.reports?.vitalsAvg[index]?.diastolic || 0),
      value: Math.floor(dbstore.reports?.vitalsAvg[index]?.value || 0),
    };

    Object.assign(this.comp1, obj);
    return this.comp1;
  }
  get comp2Data() {
    var obj = {};

    var vitalmonthly = _.groupBy(dbstore.reports.vitalsMonthly, "type");

    if (vitalmonthly) {
      vitalmonthly = vitalmonthly[vitalsActionStore.selectedVital];
    } else {
      vitalmonthly = []
    }
    var data = [];
    var labels = [];
    for (var i = 0; i < vitalmonthly?.length || 0; i++) {
      var d = vitalmonthly[i]["value"]
        ? vitalmonthly[i]["value"]
        : vitalmonthly[i]["systolic"];
      data.push(d);
      labels.push(moment(vitalmonthly[i]["month"], "MM").format("MMM"));
    }
    obj = {
      name: this.vitalMetadata[vitalsActionStore.selectedVital].name,
      unit: this.vitalMetadata[vitalsActionStore.selectedVital].units,
      color: this.vitalMetadata[vitalsActionStore.selectedVital].color,

      data: data.reverse(),
      labels: labels.reverse(),
    };
    console.log(obj);
    Object.assign(this.comp2, obj);

    return this.comp2;
  }
  get comp3Data() {
    var obj = {
      key: vitalsActionStore.selectedVital,
      name: this.vitalMetadata[vitalsActionStore.selectedVital].name,
      unit: this.vitalMetadata[vitalsActionStore.selectedVital].units,
      color: this.vitalMetadata[vitalsActionStore.selectedVital].color,

    };
    Object.assign(this.comp3, obj)
    return this.comp3;
  }
  get comp4Data() {
    var obj = {};
    var values = [];
    dbstore.vitalRecords.forEach((vr) => {
      values.push(vr["vitals"]);
    });
    obj = {
      key: vitalsActionStore.selectedVital,
      values: values,
    };

    Object.assign(this.comp4, obj);
    return this.comp4;
  }
}

const vitalsStore = new Store();
export { vitalsStore };
