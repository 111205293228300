import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./App.css";
import { initializeApp } from 'firebase/app';

import { getFirebaseConfig } from './firebase-config.js';
import HelpStore from "./dataLayer/stores/pages/help";
import { addDoc, doc, getFirestore } from "firebase/firestore";

const firebaseAppConfig = getFirebaseConfig();
initializeApp(firebaseAppConfig);

const db = getFirestore();
HelpStore.setDb(db)
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
