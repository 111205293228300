import { Col, Row, Layout } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { dbstore } from "../../dataLayer/stores/dbStore";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import ScrollToTopOnMount from "../nav/scrollToTopOnMount";
import PdfDisplay from "../PdfDisplay";
import SignAndApprove from "../SignAndApprove";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 1,
};
const Pdfview = observer(({ props }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <ScrollToTopOnMount />
        <Col span={18}>
          <div style={(box, { backgroundColor: "white", borderRadius: 30 })}>
            <PdfDisplay />
          </div>
        </Col>
        <Col span={6}>
          <div style={box}>
            <SignAndApprove data={{ signature: profileStore.comp1Data.signature, signed: homeStore.comp5Data[dbstore.selectedCompliance].signed }} />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Pdfview;
