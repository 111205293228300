import { Col, Row, Layout } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { rxDetailsStore } from "../../dataLayer/stores/pages/rxDetailsStore";
import RxInfo from "../RxInfo";
import RxInfo2 from "../RxInfo2";

var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const RxDetails = observer(({ data }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <RxInfo data={rxDetailsStore.comp1Data} />
          </div>
        </Col>
        <Col span={24}>
          <div style={box}>
            <RxInfo2 data={rxDetailsStore.comp2Data} />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default RxDetails;
