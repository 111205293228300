import "./App.css";
import SideNav from "./components/nav/SideNav";
import { BrowserRouter as Router } from "react-router-dom";
import { Row, Col, Layout, Affix, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { dbstore } from "./dataLayer/stores/dbStore";
import RouteSwitcher from "./components/nav/routeSwitcher";
import { store } from "./dataLayer/stores/store";
import TopNav from "./components/nav/TopNav";
import { LoadingOutlined } from "@ant-design/icons";
import { Helmet } from 'react-helmet'
import Login from "./components/routes/Login";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// import "antd/dist/antd.css";
const { Header, Content, Footer, Sider } = Layout;

dbstore.getData();
// setInterval(() => {
//   store.value += 1;
// }, 1000);
const App = observer(() => {
  return (
    <Router basename="/patient">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Doctor's Pharmacy User</title>
        <link rel="icon" href="/assets/logo.png" type="image/gif" sizes="16x16" />

      </Helmet>
      {!dbstore.login && <Login />}
      {dbstore.login &&
        <Layout style={{ minHeight: "100vh" }}>
          <Row>
            <Col span={4}>
              <Affix>
                <SideNav store={store} />
              </Affix>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Affix>
                    <TopNav />
                  </Affix>
                </Col>
              </Row>
              <Row
                style={{
                  padding: 50,
                  paddingTop: 20,
                  paddingRight: 70,
                  paddingLeft: 70,
                }}
              >
                <Col span={24}>
                  <Spin
                    tip="Loading..."
                    spinning={dbstore.loading}
                    indicator={antIcon}
                  >
                    <RouteSwitcher />
                  </Spin>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>}
    </Router>
  );
});
export default App;
