import { Col, Row, Layout } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { rxListActionStore } from "../../dataLayer/stores/actions/rxListActionStore";
import { rxListStore } from "../../dataLayer/stores/pages/rxListStore";
import RxListTable from "../RxListTable";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const RxList = observer(({ data }) => {
  let history = useHistory();
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <RxListTable
              data={rxListStore.comp1Data.rxarr}
              onClick={(rxno) => {
                console.log(rxno);
                history.push(`/rxdetails`);
                rxListActionStore.setComp1({ selectedRxno: rxno })
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default RxList;
