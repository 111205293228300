import { Col, Row, Layout, Input, Dropdown, Menu, Divider, Avatar } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { HiSupport } from "react-icons/hi";
import { IoChatbubblesSharp } from "react-icons/io5";
import { IoMdNotifications } from "react-icons/io";
import NotificationMenu from "../NotificationMenu";
import { DownOutlined } from "@ant-design/icons";
import ProfilePic from "../ProfilePic";
import { profileStore } from "../../dataLayer/stores/pages/profileStore";
import ProfileMenu from "../ProfileMenu";


const TopNav = observer(({ data }) => {

  return (
    <Row style={{ padding: 20, backgroundColor: "#fff" }}>
      <Col span={6}>
        <Input
          size="large"
          width={"auto"}
          bordered={false}
          placeholder="Search Prescription, Doctor Pharmacy"
          prefix={<SearchOutlined style={{ color: "#ccc" }} />}
        />
      </Col>
      <Col
        span={4}
        style={{
          marginLeft: "auto",
          borderRight: "1px solid #ccc",
          paddingRight: 30,
        }}
      >
        <Row>
          <Col style={{ marginLeft: "auto" }}>
            <Link to="/help" className={"nav-link"}>
              <HiSupport className={"nav-icon"} style={{ color: "#ccc" }} />
            </Link>
          </Col>
          <Col>
            <Link to="/messages" className={"nav-link"}>
              <IoChatbubblesSharp
                className={"nav-icon"}
                style={{ color: "#ccc" }}
              />
            </Link>
          </Col>
          <Col>
            <Dropdown overlay={NotificationMenu} trigger={["click"]}>
              {/* <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                
              </a> */}
              <IoMdNotifications
                style={{
                  marginTop: 13.5,
                  cursor: "pointer",
                  color: "#ccc",
                  marginLeft: 13,
                }}
              />
            </Dropdown>
            {/* <Divider type="vertical" /> */}
          </Col>
        </Row>
      </Col>

      <Col span={4}>
        <Row>
          <Col>
            <Dropdown overlay={ProfileMenu} trigger={["click"]}>
              {/* <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                
              </a> */}

              <div
                style={{
                  // marginTop: 9.5,
                  cursor: "pointer",
                  color: "#aaa",
                  marginLeft: 13,
                }}
              >
                <span style={{ color: "#333", marginRight: 15 }}>
                  {profileStore.comp1Data.name}
                </span>
                <DownOutlined />
                <Avatar
                  shape="circle"
                  style={{ marginLeft: 15 }}
                  size={45}
                  icon={<ProfilePic avatar={profileStore.comp1Data.avatar} />}

                />
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
export default TopNav;
