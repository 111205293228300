import { Col, Row, Layout } from "antd";
import {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
} from "react";
import { observer } from "mobx-react";
import {
  getObjectByKey,
  leadingZero,
  parsePillTime,
  startOfDay,
} from "../Common.fns";
import moment from "moment";
import { Colours } from "../Colours";
const SelectSlider = observer(({ data, onChange }) => {
  const arrLength = 7;
  const initialIndex = 3;
  const getDates = useCallback((milliseconds = Date.now()) => {
    let tempDates = [];
    for (let i = 0; i < arrLength; i++) {
      let date = startOfDay(
        moment(milliseconds)
          .add(i - 3, "days")
          .format("x")
      );
      tempDates.push(date);
    }
    return tempDates;
  }, []);
  const [dates] = useState(getDates());
  const [selectedIndex, setSelectedIndex] = useState(initialIndex);
  const selectedDate = useRef(dates[selectedIndex] || Date.now());
  onChange(dates[selectedIndex]);
  const dateContainerRef = useRef();
  const highlightRef = useRef();
  const selectedDateRef = useRef();
  const gapWidth = useRef();
  const [currentCompliance, setCurrentCompliance] = useState();

  useLayoutEffect(() => {
    if (dateContainerRef.current.children.length > 1) {
      calculateGapWidth();
      if (!selectedDateRef.current) {
        selectedDateRef.current =
          dateContainerRef.current.children[selectedIndex];
        adjustHighlight();
      }
    }
    const onResize = () => {
      calculateGapWidth();
      adjustHighlight();
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  const calculateGapWidth = () => {
    const children = dateContainerRef.current.children;
    const reducer = (accumulator, currentValue) =>
      accumulator + currentValue.offsetWidth;
    gapWidth.current =
      (dateContainerRef.current.offsetWidth -
        Array.from(children).reduce(reducer, 0)) /
      children.length;
    console.log({ gapWidth: gapWidth.current });
  };

  const adjustHighlight = () => {
    let to = selectedDateRef.current.offsetLeft;
    let from = highlightRef.current.offsetLeft;
    let toWidth = selectedDateRef.current.offsetWidth;
    highlightRef.current.style.transform = `translateX(${
      to - from - gapWidth.current
    }px)`;
    highlightRef.current.style.width = `${toWidth + gapWidth.current * 2}px`;
    selectedDateRef.current.style.color = "white";
  };
  return (
    <>
      <Row
        ref={dateContainerRef}
        className={" mx-0 justify-content-around my-3 w-75"}
        style={{ position: "relative", height: 24 }}
        align="middle"
      >
        {dates.map((val, ind) => {
          return (
            <span
              id={`dateButton-${ind}`}
              type="button"
              className={"px-0 "}
              key={ind}
              style={{
                fontSize: 16,
                color: Colours.maroon,
                zIndex: 10,
                transition: "300ms ease-in-out",
              }}
              onClick={function () {
                if (selectedDateRef.current)
                  selectedDateRef.current.style.color = Colours.maroon;
                selectedDateRef.current = document.getElementById(
                  `dateButton-${ind}`
                );
                setSelectedIndex(ind);
                selectedDate.current = dates[ind];
                console.log(dates[ind]);
                onChange(dates[ind]);
                adjustHighlight();
              }}
            >
              {new Date().getDate() === moment(parseInt(val)).date()
                ? "Today"
                : leadingZero(moment(parseInt(val)).date(), 2)}
            </span>
          );
        })}
        <div
          id={"highlight"}
          ref={highlightRef}
          style={{
            backgroundColor: Colours.primaryText,
            borderRadius: "2vh",
            width: 30,
            position: "absolute",
            zIndex: 5,
            left: 0,
            top: 0,
            bottom: 0,
            transition: "300ms ease-in-out",
          }}
        ></div>
      </Row>
    </>
  );
});
export default SelectSlider;
