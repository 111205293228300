import {
    makeObservable,
    makeAutoObservable,
    observable,
    computed,
    action,
    flow,
  } from "mobx";
  import {dbstore} from "../dbStore"
  // import {reportsActionStore} from "../actions/reportsActionStore"
  
  import{reportsActionStore} from "../actions/reportsActionStore"

  
  
  
  export default class Store {
    comp1={}
    comp2={}
    comp3={}
    comp4={}
    comp5={}
    comp6={}
    constructor() {
      this.dbstore = dbstore;
      makeAutoObservable(this);
    }
    
    get comp1Data(){
      // console.log("comp1Data")
      var period = "Daily"
      var val=60;
      var datalist =[{missed: 0,
                      missedPercent: 0,
                      taken: 2,
                      takenPercent: 0.2222222222222222,
                      total: 9,
                      unanswered: 7,
                      unansweredPercent: 0.7777777777777778
                    }]
      if (this.dbstore.reports) {
        datalist = []
        period = reportsActionStore.comp1.period
        // console.log(this.dbstore.reports.last6Avg)
        switch(period){
          case "Daily" :
            datalist = this.dbstore?.reports?.last6Avg.days
            val=this.dbstore?.reports?.pillcomplianceAvg?.day?.takenPercent*100
            break;
          case "Weekly":
            datalist = this.dbstore?.reports?.last6Avg.weeks
            val=this.dbstore?.reports?.pillcomplianceAvg?.week?.takenPercent*100
            break
          case "Monthly":
            datalist = this.dbstore?.reports?.last6Avg.months
            val=this.dbstore?.reports?.pillcomplianceAvg?.month?.takenPercent*100
            break
          case "Yearly":
            datalist = this.dbstore?.reports?.last6Avg.years
            val=this.dbstore?.reports?.pillcomplianceAvg?.year?.takenPercent*100
            break
          default:
            datalist = this.dbstore?.reports?.last6Avg.days
            val=this.dbstore?.reports?.pillcomplianceAvg?.day?.takenPercent*100
          }
       }
      //  console.log(datalist)
      
      Object.assign(this.comp1, { graphData: datalist, timePeriod: period, percentage: val});
      return this.comp1
    }
    get comp2Data(){
      return this.comp2
    }
    get comp3Data(){
      this.comp3 = "10:00"
      return this.comp3
    }
  }
  
  
  
  const reportStore = new Store();
  export { reportStore };