/* eslint-disable no-unused-expressions */
import moment from "moment";
import _ from "lodash";

export const startOfDay = (mill) => {
  let ms = Number(mill) || moment().format("x");
  var msPerDay = 86400 * 1000;
  return ms - (ms % msPerDay);
};

export const endOfDay = (mill) => {
  let ms = Number(mill) || moment().format("x");
  var msPerDay = 86400 * 1000;
  return ms - (ms % msPerDay) + msPerDay - 1;
};

export const leadingZero = (num, digits) => {
  const result = `${num}`.padStart(digits, 0);
  return result;
};

export const docCookies = {
  getItem: function (sKey) {
    if (!sKey) {
      return null;
    }
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            "(?:(?:^|.*;)\\s*" +
              encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
              "\\s*\\=\\s*([^;]*).*$)|^.*$"
          ),
          "$1"
        )
      ) || null
    );
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (typeof sValue !== "string") sValue = JSON.stringify(sValue);
    //  console.log({ sValue, typeOf: typeof sValue });
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT"
              : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
        default:
          break;
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=" +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "") +
      (bSecure ? "; secure" : "");
    return true;
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) {
      return false;
    }
    document.cookie =
      encodeURIComponent(sKey) +
      "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
      (sDomain ? "; domain=" + sDomain : "") +
      (sPath ? "; path=" + sPath : "");
    return true;
  },
  hasItem: function (sKey) {
    if (!sKey) {
      return false;
    }
    return new RegExp(
      "(?:^|;\\s*)" +
        encodeURIComponent(sKey).replace(/[-.+*]/g, "\\$&") +
        "\\s*\\="
    ).test(document.cookie);
  },
  keys: function () {
    var aKeys = document.cookie
      .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, "")
      .split(/\s*(?:=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    }
    return aKeys;
  },
};

//finds an object by key in object array
export function getObjectByKey(obj, key, value) {
  var newobj = _.filter(obj, [key, value]);
  return newobj;
}

export const parseToBool = (value) => {
  if (value) {
    return value.toString().toLowerCase() === "true" ? true : false;
  } else {
    return false;
  }
};

export const parsePillTime = (time) => {
  let [hours, mins] = time.split(":");
  hours = parseInt(hours);
  mins = parseInt(mins);
  let a = hours > 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  mins = leadingZero(mins, 2);
  console.log({ hours, mins, a });
  // moment(parseInt(time)).format("hh:mm A");
  return `${hours}:${mins} ${a}`;
};

var pc = [
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610755200000,
    time: 1610805600000,
    status: "unanswered",
    _id: "pc-4d6ea41e-dafc-484a-96d1-a2f0e77afb83",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610755200000,
    time: 1610829000000,
    status: "taken",
    _id: "pc-a9c72f7d-327e-4592-a71f-1d20e42ebd3c",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610841600000,
    time: 1610870400000,
    status: "taken",
    _id: "pc-18751e18-c012-440f-8379-74220fcf68fd",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610841600000,
    time: 1610892000000,
    status: "taken",
    _id: "pc-576c1eb5-0be9-4e4e-9b5f-39c51f65cc81",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610841600000,
    time: 1610915400000,
    status: "taken",
    _id: "pc-69c110f2-ecd2-426b-a04e-e2dfb732e4e8",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610928000000,
    time: 1610956800000,
    status: "missed",
    _id: "pc-c3a2c837-0cb3-4de2-9785-a8891f8e1ecb",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610928000000,
    time: 1610978400000,
    status: "unanswered",
    _id: "pc-b9f0417f-8f6d-421e-a65f-09912fe7249f",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1610928000000,
    time: 1611001800000,
    status: "unanswered",
    _id: "pc-9fc01cbd-b267-4e6e-bde6-7ff535dfbc4e",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1611014400000,
    time: 1611043200000,
    status: "missed",
    _id: "pc-f1623366-3fc5-4bde-892c-7034e91989bd",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1611014400000,
    time: 1611064800000,
    status: "taken",
    _id: "pc-01132571-3453-403d-bab0-97d9d219ce28",
  },
  {
    rxid: "1525e990-f0a3-4942-ac1f-93d73659a2fe",
    date: 1611014400000,
    time: 1611088200000,
    status: "taken",
    _id: "pc-8f91e0d8-d4b3-4bfa-8b3b-27f4d6f90356",
  },
];
function getLastNWeekRange(n) {
  var range = [];
  for (var i = 0; i < n; i++) {
    range.push({
      start: moment()
        .weekday(0 - i * 7)
        .startOf("day")
        .format("x"),
      end:
        moment()
          .weekday(7 - i * 7)
          .startOf("day")
          .format("x") - 1,
    });
  }
  return range;
}

function getLastNMonthRange(n) {
  var range = [];
  range.push({
    start: moment().subtract(0, "months").date(1).startOf("day").format("x"),
    end: moment().add(1, "months").date(0).endOf("day").format("x"),
  });
  for (var i = 1; i < n; i++) {
    range.push({
      start: moment().subtract(i, "months").date(1).startOf("day").format("x"),
      end: moment()
        .subtract(i - 1, "months")
        .date(0)
        .endOf("day")
        .format("x"),
    });
  }
  return range;
}
function getLastNDayRange(n) {
  var range = [];

  for (var i = 0; i < n; i++) {
    range.push({
      start: moment().subtract(i, "days").startOf("day").format("x"),
      end: moment().subtract(i, "days").endOf("day").format("x"),
    });
  }
  //  console.log(range);
  return range;
}
function groupStackDataByRange(range, arr) {
  var obj = { taken: 0, missed: 0, unanswered: 0 };
  const getKeyName = (val) => {
    switch (val) {
      case true:
        return "taken";
      case false:
        return "missed";
      case null:
        return "unanswered";
      default:
        return "unanswered";
    }
  };
  //  console.log(range);
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].date >= range.start && arr[i].date <= range.end) {
      obj[getKeyName(arr[i].status)] += 1;
    }
  }
  return obj;
}

export function createStackedBarData(pillsarr, type, n) {
  var arr = [];
  var labels = [];
  var takenarr = [];
  var missedarr = [];
  var unansweredarr = [];
  if (type == "daily") {
    var dayRange = getLastNDayRange(n);
    for (var i = 0; i < n; i++) {
      var str = "day " + i;
      labels.push(str);
      var obj = groupStackDataByRange(dayRange[i], pillsarr);
      takenarr.push(obj.taken);
      missedarr.push(obj.missed);
      unansweredarr.push(obj.unanswered);
    }
  } else if (type == "weekly") {
    var weekRange = getLastNWeekRange(n);
    for (var i = 0; i < n; i++) {
      var str = "week " + i;
      labels.push(str);
      var obj = groupStackDataByRange(weekRange[i], pillsarr);
      takenarr.push(obj.taken);
      missedarr.push(obj.missed);
      unansweredarr.push(obj.unanswered);
    }
  } else if (type == "monthly") {
    var monthRange = getLastNMonthRange(n);
    for (var i = 0; i < n; i++) {
      var str = "month " + i;
      labels.push(str);
      var obj = groupStackDataByRange(monthRange[i], pillsarr);
      takenarr.push(obj.taken);
      missedarr.push(obj.missed);
      unansweredarr.push(obj.unanswered);
    }
  } else if (type == "yearly") {
    labels.push("year");
    var obj = groupStackDataByRange(
      { start: 0, end: moment().format("x") },
      pillsarr
    );
    takenarr.push(obj.taken);
    missedarr.push(obj.missed);
    unansweredarr.push(obj.unanswered);
  }

  //  console.log(takenarr, missedarr, unansweredarr);

  var barChartData = {
    labels: labels,
    datasets: [
      {
        label: "Taken",
        //backgroundColor: window.chartColors.red,
        data: takenarr,
      },
      {
        label: "Missed",
        //backgroundColor: window.chartColors.red,
        data: missedarr,
      },
      {
        label: "Unanswered",
        //backgroundColor: window.chartColors.red,
        data: unansweredarr,
      },
    ],
  };

  return barChartData;
}

export function getMixedBarGraphData(pillsarr, ndays) {
  var labels = [];
  var takenarr = [];
  var remindersarr = [];
  var dayRange = getLastNDayRange(ndays);
  for (var i = 0; i < ndays; i++) {
    var str = "day " + i;
    labels.push(str);
    var obj = groupStackDataByRange(dayRange[i], pillsarr);
    // console.log({ obj });
    takenarr.push(obj.taken);
    remindersarr.push(obj.taken + obj.missed + obj.unanswered);
  }
  //  console.log(takenarr, remindersarr);
  var chartData = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Pill Reminders",
        data: remindersarr,
        borderWidth: 1,
        radius: 5,
        order: 2,
      },
      {
        label: "Pills Taken",
        data: takenarr,
        borderColor: "#A3A0FB",
        fill: false,
        pointBackgroundColor: "#A3A0FB",
        pointRadius: 3,
        type: "line",
        order: 1,
      },
    ],
  };
  return chartData;
}

export const checkEmpty = (form, items = []) => {
  let isValid = true;
  items.forEach((item, ind) => {
    let value;
    if (item.includes(".")) {
      let vals = item.split(".");
      value = form;
      vals.forEach((key, idx) => {
        value = value[key];
      });
    } else {
      value = form[item].value;
    }
    if (value === "") {
      console.log("not valid");
      isValid = isValid && false;
    } else {
      console.log("valid");
      isValid = isValid && true;
    }
  });
  return isValid;
};

export function generateWebCalenderEvents(data = { pills: [], refills: [] }) {
  var arr = [];
  let id = 0;
  for (let i = 0; i < data.pills.length; i++) {
    let [hours, mins] = data.pills[i].time.split(":");
    let date = moment
      .utc(data.pills[i].date)
      .set({ hours: hours, minutes: mins });
    arr.push({
      id: id++,
      title: "Your Daily Dosage",
      start: new Date(
        date.year(),
        date.month(),
        date.date(),
        date.hours(),
        date.minutes()
      ),
      end: new Date(
        date.year(),
        date.month(),
        date.date(),
        date.hours(),
        date.minutes() + 15
      ),
    });
  }
  for (let i = 0; i < data.refills.length; i++) {
    let date = moment(+data.pills[i].date);
    arr.push({
      id: id++,
      title: "Refill",
      start: new Date(
        date.year(),
        date.month(),
        date.date(),
        date.hours(),
        date.minutes()
      ),
      end: new Date(
        date.year(),
        date.month(),
        date.date(),
        date.hours(),
        date.minutes()
      ),
    });
  }
  return arr;
}

// export const getData = () => {
//   return getMixedBarGraphData(
//     dmStore.pills.pillCompliance || [],
//     uiStore.homeChartDuration
//   );
// };
