
import _ from "lodash";
import {
    makeObservable,
    makeAutoObservable,
    observable,
    computed,
    action,
    flow,
} from "mobx";
import moment from "moment";
import { dbstore } from "../dbStore";

export default class Store {

    comp1 = {}
    comp2 = {}
    selectedChannelIndex = null;
    constructor() {
        makeAutoObservable(this);
    }

    get comp1Data() {
        if (!dbstore.chatChannels) {
            return
        }
        var obj = { arr: [] };
        var arr = dbstore.chatChannels;
        arr.forEach(item => {
            var o = { ...item, name: "Yooo" }
            obj.arr.push(o)
        });

        Object.assign(this.comp1, obj)
        return this.comp1
    }
    get comp2Data() {
        if (!dbstore.chatChannels) {
            return
        }

        var index = this.selectedChannelIndex || 0;

        var obj = { arr: [] };
        var arr = dbstore.chatChannels[index]?.messages.items || [];
        var ch = dbstore.chatChannels[index]
        console.log(index, arr)

        arr.forEach(item => {
            var o = {
                message: item.body, date: moment(item.dateUpdated).format('MMM DD'),
                time: moment(item.dateUpdated).format('HH:MM A'), source: item.author == dbstore.decodedUserId ? 'receiver' : 'sender'
            }
            obj.arr.push(o)
        });
        obj.submit = async (v) => {
            if (v == '') {
                return
            }
            ch.channel.sendMessage(v)
        }

        Object.assign(this.comp2, obj)
        return this.comp2
    }

    setSelectedChannel(i) {
        this.selectedChannelIndex = i;
    }
}

const chatStore = new Store();
export { chatStore };

