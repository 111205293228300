const ChatListItem = ({ date, name, description, image, active }) => {
  return (
    <div
      className={`list-group-item list-group-item-action rounded-0 ${
        active ? "active text-white" : "list-group-item-light"
      }`}
      type={"button"}
    >
      <div className="media">
        <div className="media-body">
          <div className="d-flex align-items-center justify-content-between mb-1">
            <h6 className="mb-0">{name}</h6>
            <small className="small font-weight-bold">{date}</small>
          </div>
          <p className="font-italic mb-0 text-small">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;
