import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { inject, observer } from "mobx-react";
import { Doughnut, Bar } from "react-chartjs-2";
import _ from "lodash";
import { toJS } from "mobx";
import moment from "moment";

// import { getMixedBarGraphData } from "../Common.fns";
const ComplianceChart = observer((props) => {
  // const rand = () => Math.round(Math.random() * 20 - 10);
  // var randoms = () => [...Array(30)].map(() => Math.floor(Math.random() * 20));
  var data = props.data.graphData
  var period = props.data.timePeriod
  var reminderlist = []
  var takenlist = []
  var labels = []
  for (var i = 0; i < period; i++) {
    var date = moment().utc().startOf('day').subtract(i, 'days').format('DD MMM')
    var dateX = moment().utc().startOf('day').subtract(i, 'days').format('x')
    // console.log(toJS(data[i]))
    // console.log(dateX, Number(data[i]?.date) || null, 'date comp')
    // var pillDate = Number(data[i]?.date) || null

    var pilldata = _.find(data, { date: dateX })


    if (pilldata) {
      reminderlist.push(pilldata.total)
      takenlist.push(pilldata.taken)
    }
    else {
      reminderlist.push(0)
      takenlist.push(0)
    }

    labels.push(date)
  }
  _.reverse(reminderlist)
  _.reverse(takenlist)
  _.reverse(labels)
  const graphdata = {
    labels: labels,
    datasets: [
      {
        type: "bar",
        label: "Pill Reminders",
        data: reminderlist,
        borderWidth: 1,
        radius: 5,
        order: 2,
      },
      {
        label: "Pills Taken",
        data: takenlist,
        borderColor: "#A3A0FB",
        backgroundColor: "#A3A0FB",
        fill: false,
        pointBackgroundColor: "#A3A0FB",
        pointRadius: 3,
        type: "line",
        order: 1,
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    //responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRation: false,
  };

  return (
    <Bar
      data={graphdata}
      // width={100}
      height={100}
    //options={options}
    />
  );
});

export default ComplianceChart;
