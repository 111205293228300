import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Document, Page, pdfjs } from "react-pdf";
import dummypdf from "../assets/dummy.pdf";
import { homeStore } from "../dataLayer/stores/pages/homeStore";
import { dbstore } from "../dataLayer/stores/dbStore";
var names = {
  "hipaa":
  {
    "name": "HIPPA",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/HIPPA.pdf"
  },
  "prescriptionTransfer":
  {
    "name": "Prescription Transfer",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/Transfer%20Request.pdf"
  },
  "autoRefill":
  {
    "name": "Auto-Refill",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/Auto-Refill.pdf"
  },
  "autoPay":
  {
    "name": "Auto-Pay",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/Auto-Pay.pdf"
  },
  "autoDelivery":
  {
    "name": "Auto-Delivery",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/Auto-Delivery.pdf"
  },
  "digitalSignature":
  {
    "name": "Digital Signature",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/HIPPA.pdf"
  },
  "pillPackEnrollment":
  {
    "name": "PillPack Enrollment",
    "url": "https://testbucket13rw3.s3.sa-east-1.amazonaws.com/HIPPA.pdf"
  }
}
const PdfDisplay = observer(({ props }) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    console.log(names[dbstore.selectedCompliance].url)
  }, [])
  return (
    <>
      <Document file={{ url: names[dbstore.selectedCompliance].url }} onLoadSuccess={onDocumentLoadSuccess}>
        {Array(numPages)
          .fill(0)
          .map((v, i) => {
            return <Page pageNumber={i + 1} />;
          })}
      </Document>
    </>
  );
});
export default PdfDisplay;
