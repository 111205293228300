import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import PillAdherenceStatusCard from "./PillAdherenceStatusCard";
const PillsByDate = observer(({ data, onClick }) => {
  return (
    <>
      <div className={"row mx-0 justify-content-start"}>
        {data.map((compliance, i) => {
          return (
            <PillAdherenceStatusCard
              // key={ind}
              // complianceInfo={compliance}
              compliance={compliance}
              className="mx-2"
              onClick={(time) => {
                onClick(time);
              }}
            />
          );
        })}
      </div>
    </>
  );
});
export default PillsByDate;
