import { Col, Row, Layout, Button } from "antd";
import { Image } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Slider, withStyles } from "@material-ui/core";
import fastingOriginal from "../assets/images/restaurant-icon@2x.png";
import preMealOriginal from "../assets/images/hot-soup-bowl-icon@2x.png";
import postMealOriginal from "../assets/images/meal-icon@2x.png";
import beforeSleepOriginal from "../assets/images/moon-icon@2x.png";
import generalOriginal from "../assets/images/standing-up-man-icon@2x.png";

import fastingAlt from "../assets/images/restaurant-icon-selected@2x.png";
import preMealAlt from "../assets/images/hot-soup-bowl-icon-selected@2x.png";
import postMealAlt from "../assets/images/meal-icon-selected@2x.png";
import beforeSleepAlt from "../assets/images/moon-icon-selected@2x.png";
import generalAlt from "../assets/images/standing-up-man-icon-selected@2x.png";

const StyledSlider = ({
  color,
  onChangeCommitted,
  defaultValue,
  ...otherProps
}) => {
  const PrettoSlider = withStyles({
    root: {
      color: "#52af77",
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#FFFFFF",
      border: `2px solid ${color}`,
      marginTop: -10,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    valueLabel: {
      left: "calc(-50% + 4px)",
      color: { color },
    },
    colorPrimary: { color },
    track: {
      height: 4,
      borderRadius: 4,
      color: { color },
    },
    rail: {
      height: 4,
      borderRadius: 4,
      color: { color },
    },
  })(Slider);
  return (
    <PrettoSlider
      valueLabelDisplay="on"
      aria-label="pretto slider"
      defaultValue={defaultValue || 50}
      onChangeCommitted={onChangeCommitted}
    />
  );
};

const BloodGlucoseCurrentStatusInput = ({
  color,
  onChange = () => { },
  defaultValue = "",
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const availableOptions = useRef({
    original: {
      fasting: fastingOriginal,
      "pre-meal": preMealOriginal,
      "post-meal": postMealOriginal,
      "before-sleep": beforeSleepOriginal,
      general: generalOriginal,
    },
    alt: {
      fasting: fastingAlt,
      "pre-meal": preMealAlt,
      "post-meal": postMealAlt,
      "before-sleep": beforeSleepAlt,
      general: generalAlt,
    },
  });
  return (
    <div className=" d-flex justify-content-around">
      {Object.keys(availableOptions.current.original).map((key, ind) => {
        let isSelected = selectedOption === key;
        return (
          <div
            style={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="mx-auto"
              onClick={() => {
                console.log({ key });
                setSelectedOption(key);
                onChange(key);
              }}
              style={{
                display: "flex",
                backgroundColor: isSelected ? color : "#E4E4E4",
                height: 60,
                width: 60,
                borderRadius: 30,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                src={
                  isSelected
                    ? availableOptions.current.alt[key]
                    : availableOptions.current.original[key]
                }
                style={{
                  // width: 40,
                  height: "50%",
                  objectFit: "contain",
                }}
              />
            </div>
            <div
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: 12,
                color: isSelected ? color : "#ACACAC",
                marginVertical: 5,
                textTransform: "capitalize",
                textAlign: "center",
              }}
            >
              {key.split("-").join(" ")}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const VitalInput = observer(({ data, onSubmit = () => { } }) => {
  if (!data) {
    data = {
      name: "Blood Pressure",
      key: "bloodPressure",

      unit: "cmHg",
    };
  }
  const [currentValue, setCurrentValue] = useState({
    value: 100,
  });
  const curVal = useRef({
    value: 100,
  });
  const editCurrentVal = (key, val) => {
    let temp = { ...currentValue };
    temp[key] = val;
    setCurrentValue({ ...temp });
    curVal.current = temp;
    console.log(curVal.current, currentValue)
  };
  const colors = {
    bloodPressure: "#FF6773",
    bloodGlucose: "#6F66D1",
    temperature: "#0B82BA",
    weight: "#574FFA",
    oxygenLevels: "#ED8F03",
  };
  useEffect(() => {
    switch (data.key) {
      case "bloodPressure":
        setCurrentValue({
          systolic: 130,
          diastolic: 90,
        });
        curVal.current = {
          systolic: 130,
          diastolic: 90,
        };
        break;
      case "bloodGlucose":
        setCurrentValue({
          value: 150,
          currentStatus: "post-meal",
        });
        curVal.current = {
          value: 150,
          currentStatus: "post-meal",
        };
        break;
      default:
        break;
    }
    // onChange(curVal.current);
  }, [data.key]);

  const GetInput = () => {
    switch (data.key) {
      case "bloodPressure":
        return (
          <>
            <div className="mb-3">Systolic</div>
            <StyledSlider
              color={colors[data.key]}
              onChangeCommitted={(e, v) => {
                editCurrentVal("systolic", v);
                // onChange(curVal.current);
              }}
              defaultValue={currentValue.systolic}
            />
            <div className="mb-3">Diastolic</div>
            <StyledSlider
              color={colors[data.key]}
              onChangeCommitted={(e, v) => {
                editCurrentVal("diastolic", v);
                // onChange(curVal.current);
              }}
              defaultValue={currentValue.diastolic}
            />
          </>
        );
      case "bloodGlucose":
        return (
          <>
            <div className="mb-3">Blood Glucose</div>
            <StyledSlider
              color={colors[data.key]}
              onChangeCommitted={(e, v) => {
                editCurrentVal("value", v);
                // onChange(curVal.current);
              }}
              defaultValue={currentValue.value}
            />
            <div className="mb-3">Current Status</div>
            <BloodGlucoseCurrentStatusInput
              color={colors[data.key]}
              onChange={(v) => {
                editCurrentVal("currentStatus", v);
                // onChange(curVal.current);
              }}
              defaultValue={currentValue.currentStatus}
            />
          </>
        );
      default:
        return (
          <>
            <div className="mb-3">{data.name}</div>
            <StyledSlider
              color={colors[data.key]}
              onChangeCommitted={(e, v) => {
                editCurrentVal("value", v);
                // onChange(curVal.current);
              }}
              defaultValue={currentValue.value}
            />
          </>
        );
    }
  };
  return (
    <div>
      <GetInput />
      <Row align="middle">
        <Button
          style={{
            backgroundColor: "#23D098",
            color: "white",
            width: 120,
            borderRadius: 25,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 30,
          }}
          size="large"
          onClick={() => {
            var obj = { ...curVal.current, type: data.key }
            console.log(obj);
            onSubmit(obj);
          }}
        >
          Submit
        </Button>
      </Row>
    </div>
  );
});
export default VitalInput;
