import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Row, Col } from "antd";
const ToggleSwitch = ({
  defaultChecked,
  label,
  className,
  onChange,
  ...otherProps
}) => {
  const [state, setState] = useState({ checked: defaultChecked });
  useEffect(() => {
    setState({ checked: defaultChecked });
  }, [defaultChecked]);

  const handleChange = (checked) => {
    setState({ checked });
  };

  return (
    <label className="m-2" style={{ display: "block" }} type="button">
      <Row align="middle">
        <Col span={18}>
          <span
            style={{
              color: "#0E0D0D",
              fontFamily: "Nunito-SemiBold",
              fontSize: 18,
              wordBreak: "break-all",
              wordWrap: "break-word",
            }}
          >
            {label}
          </span>
        </Col>
        <Col span={label ? 0 : 6} />
        <Col
          span={label ? 6 : 2}
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <Switch
            onChange={(checked) => {
              handleChange(checked);
              onChange(checked);
            }}
            checked={state.checked}
            {...otherProps}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={"#36ECCD"}
            handleDiameter={24}
            height={36}
          />
        </Col>
      </Row>
    </label>
  );
};

export default ToggleSwitch;
