import { Button } from "react-bootstrap";
import { Colours } from "../Colours";
import { parsePillTime } from "../Common.fns";

const DoseConfirmationCard = ({
  date,
  time = "8:00",
  onYes = () => { },
  onNo = () => { },
}) => {
  return (
    <div
      className={"row m-0"}
      style={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        borderRadius: "30px",
        padding: 20,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "white",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {(time == "a") &&
        <>
          <div
            className={"col-12 col-md-8"}
            style={{
              fontFamily: "Nunito-Light",
              fontSize: "45px",
              color: Colours.primaryText,
            }}
          >
            This week's pill compliance is up to date
          </div>
        </>
      }
      {(time != "a") &&
        <>
          <div
            className={"col-12 col-md-8"}
            style={{
              fontFamily: "Nunito-Light",
              fontSize: "45px",
              color: Colours.primaryText,
            }}
          >
            <span className="text-center justify-content-center">
              Did you take your{" "}
              <span style={{ fontFamily: "Nunito-SemiBold" }}>
                {parsePillTime(time)}
              </span>{" "}
          dose?
        </span>
          </div>
          <div
            className={"col-12 col-md-4 align-self-stretch row"}
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <Button
              className={"col-12"}
              size="lg"
              style={{
                marginBottom: 10,
                borderRadius: "30px",
                backgroundColor: Colours.green,
                border: "0px transparent",
              }}
              onClick={onYes}
            >
              YES
        </Button>
            <Button
              className={"col-12"}
              size="lg"
              style={{
                marginTop: 10,
                borderRadius: "30px",
                backgroundColor: Colours.orange,
                border: "0px transparent",
              }}
              onClick={onNo}
            >
              NO
        </Button>
          </div>
        </>}
    </div>
  );
};

export default DoseConfirmationCard;
