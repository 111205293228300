const ChatContainer = ({ children }) => {
  return (
    <>
      <div
        class="container fluid pb-4"
        style={{ height: "100%", width: "100%" }}
      >
        <div class="row rounded-lg overflow-hidden shadow">{children}</div>
      </div>
    </>
  );
};
export default ChatContainer;
