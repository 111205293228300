import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import CustomizedSteppers from "./CustomizedSteppers";
import ContactButtons from "./ContactButtons";
import { Colours } from "../Colours";
import { Card } from "react-bootstrap";
import { homeStore } from "../dataLayer/stores/pages/homeStore";
const TrackRefillCard = observer(({ props }) => {
  return (
    <Card
      className={"mt-4 pb-4 pt-3"}
      style={{
        width: "100%",
        marginBottom: "10px",
        borderRadius: 30,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
      }}
    >
      <div
        className="mx-4"
        style={{
          fontFamily: "Nunito-SemiBold",
          fontSize: 33,
          color: Colours.primaryText,
        }}
      >
        Track your refill
      </div>

      {homeStore.comp4Data.time ? <>
        <CustomizedSteppers />
        <ContactButtons className={"justify-content-around"} phone={9876543210} />
      </> : <p style={{ margin: 20, fontSize: 24 }}>No upcoming refills</p>}
    </Card>
  );
});
export default TrackRefillCard;
