import { Col, Row, Layout } from "antd";

import React, { useCallback, useEffect, useRef } from "react";
import { Button, ButtonGroup, Card, Container } from "react-bootstrap";
import { Colours } from "../../Colours";

import { inject, observer } from "mobx-react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../assets/sass/calendar styles/styles.scss";

const localizer = momentLocalizer(moment);

var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
};
const CalendarComp = observer(({ props }) => {
  const setCurrentTimeIndicator = () => {
    setTimeout(() => {
      const dayNum = moment().day();
      let isWeekView =
        document.getElementsByClassName("rbc-day-slot rbc-time-column").length >
        1;
      const currentTImeIndicator = document.getElementsByClassName(
        "rbc-current-time-indicator"
      )[0];
      if (currentTImeIndicator) {
        if (isWeekView) {
          currentTImeIndicator.style.left = `-${dayNum * 100}%`;
          currentTImeIndicator.style.right = `-${(6 - dayNum) * 100}%`;
        }
        var element = document.createElement("div");
        element.style.position = "absolute";
        element.style.left = "-5px";
        element.style.top = "-5px";
        element.style.height = "10px";
        element.style.width = "10px";
        element.style.borderRadius = "5px";
        element.style.backgroundColor = "#3B86FF";
        currentTImeIndicator.appendChild(element);
      }
    }, 1);
  };
  return (
    <>
      {/* <Row className="mx-0 my-2">
        <ButtonGroup aria-label="Calendar view selector" className="ml-auto">
          <Button
            variant="outline-secondary"
            active={uiStore.calendarView.layout === "graphical"}
            onClick={() => uiStore.switchCalendarLayout("graphical")}
          >
            Graphical
          </Button>
          <Button
            variant="outline-secondary"
            active={uiStore.calendarView.layout === "calendar"}
            onClick={() => uiStore.switchCalendarLayout("calendar")}
          >
            Calendar
          </Button>
        </ButtonGroup>
      </Row> */}
      {/* {uiStore.calendarView.layout === "graphical" && (
        <>
          <div
            className="row mx-0 justify-content-center mb-4"
            ref={cardContainerRef}
          >
            {store.pills.medicationProfiles.map((val, ind) => {
              return (
                <CalendarMedCard
                  key={ind}
                  store={store}
                  cardIndex={ind}
                  className=""
                  onCardClick={onCardClick}
                />
              );
            })}
          </div>
          <Container>
            <Card
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                borderRadius: 30,
                border: "none",
                boxShadow: "none",
              }}
            >
              <Card.Body>
                <RxDetailsStepper
                  store={store}
                  index={(() => {
                    return selectedCardIndex.current;
                  })()}
                />
              </Card.Body>
            </Card>
          </Container>
        </>
      )} */}
      {true && (
        <Card
          className="mb-5"
          style={{
            marginTop: "50px",
            marginBottom: "10px",
            borderRadius: 30,
            border: "none",
            boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.16)",
          }}
        >
          <Card.Body className="d-flex flex-column">
            <Card.Title
              style={{
                fontFamily: "SourceSansPro-Regular",
                fontSize: 30,
                color: Colours.secondaryText,
              }}
            >
              Your Calendar
            </Card.Title>
            <Calendar
              // popup
              onView={setCurrentTimeIndicator}
              className="mt-4"
              localizer={localizer}
              style={{ fontFamily: "SourceSansPro-Regular", height: 750 }}
              events={[]}
              startAccessor="start"
              endAccessor="end"
            />
          </Card.Body>
        </Card>
      )}
    </>
  );
});
export default CalendarComp;
