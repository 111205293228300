const ChatMessage = ({ source, message, time, date }) => {
  const isSender = source === "sender";
  return (
    <>
      {/* <!-- Sender Message--> */}
      <div class={`media w-50 mb-3 ${isSender ? "" : "ml-auto"}`} style={{ marginLeft: isSender ? 0 : 'auto' }}>
        <div class={`media-body ${isSender ? "ml-3" : "mr-3"}`}>
          <div
            class={`rounded py-2 px-3 mb-2 ${isSender ? "bg-light" : "bg-primary"
              }`}
          >
            <p
              class={`text-small mb-0 ${isSender ? "text-muted" : "text-white"
                }`}
            >
              {message}
            </p>
          </div>
          <p class="small text-muted text-right">
            {time} | {date}
          </p>
        </div>
      </div>
    </>
  );
};
export default ChatMessage;
