import { Col, Row, Layout, message } from "antd";
import { useEffect, useState } from "react";
import { dbstore } from "../../dataLayer/stores/dbStore.js";
import ContactCard from "../ContactCard";
import MeetingForm from "../MeetingForm";
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Meeting = ({ props }) => {
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <MeetingForm
              onSubmit={async (obj) => {
                var res = await dbstore.newMeeting(obj);
                if (res == 200) {
                  message.success({
                    content: "Meeting scheduled successfully",
                    duration: 5,
                  });
                } else {
                  message.error({
                    content: "Oops! something went wrong please try again",
                    duration: 5,
                  });
                }
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <ContactCard data={{ type: "technician" }} />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <ContactCard data={{ type: "pharmacist" }} />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default Meeting;
