import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import ComplianceCard from "../ComplianceCard";
import ComplianceChartCard from "../ComplianceChartCard";
import DoseCard from "../DoseCard";
import RefillCard from "../RefillCard";
import ComplianceStatusCard from "../ComplianceStatusCard";
import ComplianceStatusCardList from "../ComplianceStatusCardLIst";
import VitalsButton from "../VitalsButton";
import DoseConfirmationCard from "../DoseConfirmationCard";
import { homeStore } from "../../dataLayer/stores/pages/homeStore";
import { homeActionStore } from "../../dataLayer/stores/actions/homeActionStore";
import { observer } from "mobx-react";
import DoseAdherenceModal from "../DoseAdherenceModal";
import { dbstore } from "../../dataLayer/stores/dbStore";
// console.log(homeStore);
var box = {
  minHeight: 100,
  backgroundColor: "transparent",
  margin: 10,
};
const Home = observer(({ props }) => {
  let history = useHistory();
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row>
            <p
              className="m-2"
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "28px",
                color: "#272D5C",
              }}
            >
              Overview
            </p>
          </Row>
          <Row>
            <Col span={8}>
              <div style={box}>
                <ComplianceCard data={homeStore.comp1Data} />
              </div>
            </Col>
            <Col span={16}>
              <div style={box}>
                <ComplianceChartCard
                  data={
                    homeStore.comp2Data
                    //   {
                    //   graphData: homeStore.comp2Data.graphdata,
                    //   timePeriod: 30,
                    // }
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <div style={box}>
            <DoseCard data={homeStore.comp3Data} />
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <RefillCard data={homeStore.comp4Data} />
          </div>
        </Col>
        <Col span={10}>
          <div style={box}>
            <p
              style={{
                fontFamily: "Nunito-SemiBold",
                fontSize: "24px",
                color: "#272D5C",
              }}
            >
              Your Compliance Status
            </p>
            <ComplianceStatusCardList
              data={homeStore.comp5Data}
              onClick={(key) => {
                dbstore.setSelectedCompliance(key);
                history.push(`/pdfview`);
              }}
            />
          </div>
        </Col>
        <Col span={14}>
          <div style={box}>
            <VitalsButton onClick={() => { history.push(`/profile`); }} />
            <DoseConfirmationCard
              time={homeStore.comp6Data.time}
              onYes={() => {
                homeActionStore.setComp6({ visible: true });
              }}
              onNo={() => {
                homeActionStore.setComp6({ visible: true });
              }}
            />
            <DoseAdherenceModal
              visible={homeStore.comp6Data.visible}
              data={
                {
                  pills: homeStore.comp6Data.pillsList,
                  time: homeStore.comp6Data.time
                }
              }
              onCancel={() => {
                homeActionStore.setComp6({ visible: false });
              }}
              onChange={homeActionStore.setPrescriptionStatus}
              onSubmit={homeActionStore.updatePrescription}
            />
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Home;
