
import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
} from "mobx";
import { prescriptionsActionStore } from "../actions/prescriptionsActionStore";
import { rxListActionStore } from "../actions/rxListActionStore";
import { dbstore } from "../dbStore";
import moment from 'moment'
export default class Store {

  comp1 = {}
  comp2 = {}
  constructor() {
    makeAutoObservable(this);
  }

  get comp1Data() {
    var rx = _.find(dbstore.rxarr, { rxno: rxListActionStore.comp1.selectedRxno })
    var obj = {
      rxno: rxListActionStore.comp1.selectedRxno,
      docName: _.find(dbstore.doctorDetails, { _id: prescriptionsActionStore.comp1.selectedDocid })['name'],
      pcAvg: dbstore?.reports?.pillcomplianceAvg?.year?.takenPercent * 100
    }
    Object.assign(this.comp1, obj)
    return this.comp1
  }
  get comp2Data() {
    var rx = _.find(dbstore.rxarr, { rxno: rxListActionStore.comp1.selectedRxno });
    var obj = {
      refills: rx.refills.length,
      currentRefill: 2,
      currentRefillStart: moment(rx.filldate).format("DD MMMM YYYY"),
      currentRefillEnd: moment(rx.expirydate).format("DD MMMM YYYY"),
      currentStatus: "Pillpack Available",
      autoRefill: true,
      lastFillDate: moment().format("DD MMMM YYYY"),
    }
    Object.assign(this.comp2, obj)

    return this.comp2
  }
}

const rxDetailsStore = new Store();
export { rxDetailsStore };

