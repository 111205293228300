import {
makeObservable,
makeAutoObservable,
observable,
computed,
action,
flow,
} from "mobx";
import { dbstore } from "../dbStore";

export default class Store {
    comp1 = {period: "Daily"};
    comp2 = {};
    comp3 = {};
    comp4 = {};
    comp5 = {};
    constructor() {
        this.dbstore = dbstore;

        makeAutoObservable(this, {
        setComp1: action,
        setComp2: action,
        setComp3: action,
        setComp4: action,
        setComp5: action,

        setComp6: action,
        });
    }

    setComp1(obj){
        Object.assign(this.comp1, obj)
        // console.log(this.comp1)
    }

}

const reportsActionStore = new Store();
export {reportsActionStore};