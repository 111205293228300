import _ from "lodash";
import {
  makeObservable,
  makeAutoObservable,
  observable,
  computed,
  action,
  flow,
  toJS,
} from "mobx";
import moment from "moment";
import { dbstore } from "../dbStore";
import { prescriptionsActionStore } from "../actions/prescriptionsActionStore";
export default class Store {
  comp1 = [];
  comp2 = { pillCard: [], pillsList: [], selectedTime: null };
  comp3 = {};
  constructor() {
    this.dbstore = dbstore;
    makeAutoObservable(this);
  }

  get comp1Data() {
    if (this.dbstore.doctorDetails) {
      //Object.assign(this.comp1, this.dbstore.doctorDetails);
      this.comp1 = this.dbstore.doctorDetails;
      console.log(toJS(this.comp1));
    }
    return this.comp1;
  }
  get comp2Data() {
    var date = prescriptionsActionStore.comp2.date;
    var arr = [];
    if (this.dbstore.pcarr && this.dbstore.rxarr) {
      for (let i = 0; i < this.dbstore.pcarr.length; i++) {
        console.log(this.dbstore.pcarr[i])
        if (!this.dbstore.pcarr[i]["name"]) {
          var rx = _.find(this.dbstore.rxarr, {
            _id: this.dbstore.pcarr[i].rxid,
          });
          console.log(rx);
          this.dbstore.pcarr[i]["name"] = rx.name;
        }
      }
      var dates = _.groupBy(this.dbstore.pcarr, "date");

      var pills = _.groupBy(dates[date], "time");
      for (var key in pills) {
        var obj = {
          time: key,
          date: date,
          totalTablets: pills[key].length,
        };
        var statusGroup = _.groupBy(pills[key], "status");
        console.log(statusGroup);
        var status = false;
        status = statusGroup["taken"]?.length > 0 ? true : status;
        status = statusGroup[null]?.length == pills[key].length ? null : status;
        obj["status"] = status;
        arr.push(obj);
      }
      var pillsarr = [];
      if (
        prescriptionsActionStore.comp2.selectedTime &&
        Object.keys(pills).length > 0 &&
        prescriptionsActionStore.comp2.selectedTime?.length > 0
      ) {
        pills[prescriptionsActionStore.comp2.selectedTime].forEach((p) => {
          var temp = {
            name: p.name,
            status: p.status,
            rxid: p.rxid,
            date: p.date,
            time: p.time,
            pouchGuid: p.pouchGuid,
            selected:
              _.filter(pills[prescriptionsActionStore.comp2.selectedTime], {
                status: null,
              }).length ==
                pills[prescriptionsActionStore.comp2.selectedTime].length
                ? true
                : p.status == "taken",
          };
          pillsarr.push(temp);
        });
      } else {
        pillsarr = [];
      }
      var o = {
        pillCard: arr,
        pillsList: pillsarr,
        selectedTime: prescriptionsActionStore.comp2.selectedTime,
        showSelect: prescriptionsActionStore.comp2.showSelect,
      };
      console.log(o);
      Object.assign(this.comp2, o);
    }
    return this.comp2;
  }
  get comp3Data() {
    return this.comp3;
  }
}

const prescriptionsStore = new Store();
export { prescriptionsStore };
