import { Col, Row, Layout, Button } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Colours } from "../Colours";
import { dbstore } from "../dataLayer/stores/dbStore";

const SignAndApprove = observer(({ data }) => {
  return (
    <>
      <div
        style={{
          height: "100vh",
          paddingTop: 60,
          borderRadius: 25,
          backgroundColor: "white",
          padding: 15,
          textAlign: "center",
        }}
      >
        <h4>Sign & Approve</h4>

        {!data.signed ? <><img src={data.signature} style={{
          height: 200,
          backgroundColor: "#F5F5F5",
          borderRadius: 25,
          padding: 30,
          marginTop: 40,
        }} />
          <p
            className="mx-3 mt-2"
            style={{ fontSize: 12, textAlign: "right", cursor: "pointer" }}
          >

          </p>
          <Button
            onClick={() => {
              dbstore.approveComplince()
            }}
            style={{
              backgroundColor: Colours.green,
              color: "white",
              width: 120,
              borderRadius: 25,
              marginTop: 40,
            }}
            size="large"
          >
            Submit
          </Button></> : <p>Already Signed</p>}
      </div>
    </>
  );
});
export default SignAndApprove;
